import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Container,
  Box,
  alpha,
  InputBase,
  Button,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import MobileNavMenu from './ResponsiveAppBarComponents/MobileNavMenu.js';
import DesktopNavMenu from './ResponsiveAppBarComponents/DesktopNavMenu.js';
import UserMenu from './ResponsiveAppBarComponents/UserMenu.js';
import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import { useQuery } from '../../context/QueryContext.js';
import { useNavigate } from 'react-router-dom';
import useLoadingState from '../../context/LoadingContext.js';
import { useAuthContext } from '../../context/AuthContext.js';
import { useStripeContext } from '../../context/StripeContext.js';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';

const DEFAULT_PAGES = ['Gallery', 'Sign Up', 'Sign In', 'About', 'Contact'];
const SETTINGS_WITH_CUSTOMER = ['Dashboard', 'Profile', 'Logout'];
const SETTINGS_WITHOUT_CUSTOMER = ['Profile', 'Subscription', 'Logout'];

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  transition: theme.transitions.create('width'),
  width: '0ch', // Initially hidden
  '&.expanded': {
    width: '20ch', // Expand when active (you can adjust this width)
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto', // Normal behavior on larger screens
  },
}));

export default function ResponsiveAppBar() {
  const [searchExpanded, setSearchExpanded] = useState(false); // State to toggle the search bar expansion
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const { customerId, loadingCustomerId } = useStripeContext();
  const { isAuthenticated } = useAuthContext();
  const { query, setQuery } = useQuery();
  const navigate = useNavigate();

  const { setPageLoading } = useLoadingState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  // Handles expanding and collapsing search input on mobile
  const handleSearchToggle = () => {
    setSearchExpanded(!searchExpanded);
  };

  // Navigates to dashboard
  const handleHomeDashboard = () => {
    setPageLoading(true);
    navigate('/dashboard');
    setAnchorElUser(null);
    setPageLoading(false);
  };

  // Navigates to subscription
  const handleSubscriptionNav = () => {
    navigate('/subscription');
  };

  return (
    <AppBar position="fixed" sx={{ maxHeight: '68.5px', top: '0px' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <MobileNavMenu
            {...{
              setAnchorElNav,
              setAnchorElUser,
              anchorElNav,
              pages: DEFAULT_PAGES,
              type: window.location.pathname.split('/')[1],
            }}
          />
          <DesktopNavMenu
            {...{
              setAnchorElNav,
              setAnchorElUser,
              pages: DEFAULT_PAGES,
              type: window.location.pathname.split('/')[1],
            }}
          />

          <Box
            sx={{
              flexGrow: isAuthenticated ? 1 : 0,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: (theme) => theme.spacing(1),
              padding: (theme) => theme.spacing(1),
            }}
          >
            {/* Show search icon and functionality only on mobile (xs and sm screens) */}
            {!window.location.pathname.split('/')[1] && (
              <Box
                sx={{
                  display: 'flex',
                  border: '1px solid',
                  borderColor: 'green',
                  paddingLeft: searchExpanded ? '18px' : '0px',
                  borderRadius: '10px',
                  // position: searchExpanded ? 'absolute' : 'relative',
                  left: searchExpanded ? '56px;' : ' 0px',
                  backgroundColor: '#202020',
                  zIndex: 9,
                  right: isMobile ? '0px' : '60px',
                }}
              >
                {!searchExpanded ? (
                  <IconButton onClick={handleSearchToggle} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <StyledInputBase
                      className={searchExpanded ? 'expanded' : ''}
                      placeholder="Search…"
                      inputProps={{ 'aria-label': 'search' }}
                      defaultValue={query}
                      onChange={(e) => setQuery(e.target.value)}
                    />
                    <IconButton onClick={handleSearchToggle} aria-label="close search">
                      <CloseIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            )}

            {!loadingCustomerId ? (
              customerId ? (
                <Button
                  onClick={handleHomeDashboard}
                  style={{
                    margin: '0 10px',
                    color: 'white',
                    display: isTablet && searchExpanded ? 'none' : 'flex',
                  }}
                >
                  Dashboard
                </Button>
              ) : (
                <Button
                  onClick={handleSubscriptionNav}
                  style={{ margin: '0 20px', color: 'white' }}
                >
                  Subscription
                </Button>
              )
            ) : null}
            <Box
              sx={{
                display: isMobile && searchExpanded ? 'none' : 'flex',
              }}
            >
              <UserMenu
                {...{
                  setAnchorElNav,
                  setAnchorElUser,
                  anchorElUser,
                  settings: customerId ? SETTINGS_WITH_CUSTOMER : SETTINGS_WITHOUT_CUSTOMER,
                }}
              />
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
