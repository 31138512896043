import React, { useState } from 'react';
import {
  // Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import { Box, CssBaseline, ThemeProvider, createTheme } from '@mui/material';

import ModeNightIcon from '@mui/icons-material/ModeNight';
import LightModeIcon from '@mui/icons-material/LightMode';

import Subscription from './components/Subscription/Subscription.js';
import './App.css';

import Auth from './components/Auth/Auth.js';
import ResponsiveAppBar from './components/ResponsiveAppBar/ResponsiveAppBar.js';
import Gallery from './components/Gallery/Gallery.js';
import NotFound from './components/NotFound.js';
import Contact from './components/Contact/Contact.js';
import GalleryPostDetail from './components/Gallery/GalleryPostDetail.js';
import NewPost from './components/NewPost/NewPost.js';
import EditPost from './components/EditPost/EditPost.js';
import Profile from './components/Profile/Profile.js';
import StripeReturn from './components/StripeReturn/StripeReturn.js';
import About from './components/About/About.js';
import DashboardTabs from './components/DashboardTabs/DashboardTabs.js';
import { ToastContainer } from 'react-toastify';
import LoginLanding from './components/LoginLanding/LoginLanding.js';
import TermsOfService from './components/TermsOfService/TermsOfService.js';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1f8e3d',
    },
    secondary: {
      main: '#1565c0',
    },
  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0c410c',
    },
    secondary: {
      main: '#1565c0',
    },
  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
});

function App() {
  const [theme, setTheme] = useState(darkTheme);
  return (
    <>
      {' '}
      <ToastContainer position="top-center" />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box className={'App'}>
          <ResponsiveAppBar />
          <Routes>
            <Route path="/" element={<Gallery />} />
            <Route path="/gallery/:id" element={<GalleryPostDetail />} />
            <Route path="/about" element={<About />} />
            <Route path="/stripe-return" element={<StripeReturn />} />
            <Route path="/dashboard" element={<DashboardTabs />} />
            <Route path="/dashboard/new" element={<NewPost />} />
            <Route path="/dashboard/edit/:id" element={<EditPost />} />
            <Route path="/profile/:sub" element={<Profile />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/subscription/:result?" element={<Subscription />} />
            <Route path="/auth/:type" element={<Auth />} />
            <Route path="/login-landing" element={<LoginLanding />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="*" element={<NotFound />} />
          </Routes>

          {theme === darkTheme ? (
            <ModeNightIcon onClick={() => setTheme(lightTheme)} className="theme-icon" />
          ) : (
            <LightModeIcon onClick={() => setTheme(darkTheme)} className="theme-icon" />
          )}
        </Box>
      </ThemeProvider>
    </>
  );
}

export default App;
