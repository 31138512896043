// useInventory.js
import { useState, useEffect } from 'react';
import { fetchInventorySnapshots } from '../services/fetch-inventory.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

export function useInventory() {
  const [snapshots, setSnapshots] = useState([]);
  const [inventoryLoading, setInventoryLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getSnapshots = async () => {
      try {
        setInventoryLoading(true);
        const data = await fetchInventorySnapshots();
        if (data === null) {
          // Handle unauthorized access silently
          // setQuotaError('Unauthorized access - please log in.'); // no inventory error state! FIX // TODO
          navigate('/auth/sign-in');
          return;
        }
        setSnapshots(data);
        setInventoryLoading(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch inventory snapshots:', error.message);

        toast.error(`Error fetching inventory: ${error}`, {
          theme: 'dark',
          draggable: true,
          draggablePercent: 60,
          toastId: 'useInventory-1',
          autoClose: false,
        });
      }
    };

    getSnapshots();
  }, []);

  return { snapshots, setSnapshots, inventoryLoading };
}
