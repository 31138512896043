import { useCallback, useEffect, useState } from 'react';
import { downloadInventoryCSV, uploadImagesAndCreatePost } from '../../services/fetch-utils.js';
import './PostForm.css';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DashboardSubMgt from '../Subscription/SubscriptionPages/DashboardSubMgt/DashboardSubMgt.js';

import { useDropzone } from 'react-dropzone';
import { usePosts } from '../../hooks/usePosts.js';
import { useAuthContext } from '../../context/AuthContext.js';
import { useStripeContext } from '../../context/StripeContext.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function PostForm({
  title = '',
  description = '',
  price = '',
  category = '',
  submitHandler,
  imageUrls,
  sold = false,
}) {
  // form wasn't showing current values in edit mode, this fixed it
  useEffect(() => {
    if (title) {
      setTitleInput(title);
      setDescriptionInput(description);
      setPriceInput(price);
      setCategoryInput(category);
      setCurrentImages(imageUrls);
      setSoldInput(sold);
    }
  }, [title, description, price, category, imageUrls, sold]);
  const { restricted } = usePosts();
  const { customerId } = useStripeContext();
  const { error } = useAuthContext();
  const [titleInput, setTitleInput] = useState(title);
  const [descriptionInput, setDescriptionInput] = useState(description);
  const [priceInput, setPriceInput] = useState(price);
  const [categoryInput, setCategoryInput] = useState(category);

  const [loading, setLoading] = useState(false);
  const [currentImages, setCurrentImages] = useState(imageUrls || []); // Added state for images currently in the post for display in the form
  const [deletedImages, setDeletedImages] = useState([]);

  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [soldInput, setSoldInput] = useState(sold); // set to current value for edit functionality

  const [files, setFiles] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
  });

  // Display thumbnails
  const thumbs = (
    <Box className="thumbnails-container" sx={{ minHeight: '100px', marginTop: '20px' }}>
      {/* Display newly selected files */}
      {files.map((file, index) => (
        <div key={file.name} className="thumbnail-wrapper">
          <img src={file.preview} alt={`New image ${index + 1}`} className="thumbnail" />
          <button
            type="button"
            className="delete-button-form"
            onClick={(e) => {
              e.preventDefault();
              handleImageDelete(index);
            }}
          >
            X
          </button>
        </div>
      ))}
      {/* Display current images */}
      {currentImages.map((url, index) => (
        <div key={url} className="thumbnail-wrapper">
          <img src={url} alt={`Current image ${index + 1}`} className="thumbnail" />
          <button
            type="button"
            className="delete-button-form"
            onClick={(e) => {
              e.preventDefault();
              handleImageDelete(files.length + index);
            }}
          >
            X
          </button>
        </div>
      ))}
    </Box>
  );

  let newOrEdit = '';
  let formFunctionMode = '';
  if (title) {
    newOrEdit = 'Edit Post';
    formFunctionMode = 'edit';
  } else {
    newOrEdit = 'New Gallery Post';
    formFunctionMode = 'new';
  }

  // This is for deleting images from the form before submitting
  const handleImageDelete = (index) => {
    // Deleting a newly uploaded file
    if (index < files.length) {
      setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    } else {
      // Deleting an existing image
      // Adjust the index to target the correct image in currentImages
      const currentIndex = index - files.length;
      setCurrentImages((prevImages) => prevImages.filter((_, i) => i !== currentIndex));

      // If you need to track which existing images have been deleted
      const deletedImageUrl = currentImages[currentIndex];
      setDeletedImages((prevDeletedImages) => [...prevDeletedImages, deletedImageUrl]);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const postDetails = {
        title: titleInput,
        description: descriptionInput,
        price: priceInput,
        category: categoryInput,
        num_imgs: files.length,
        customerId: customerId,
        sold: soldInput,
      };

      // Upload new images to Cloudinary and get their URLs + post details
      const newPost = {
        ...(await uploadImagesAndCreatePost(files, formFunctionMode)),
        ...postDetails,
      };

      // pass new post and images to parent component
      submitHandler(newPost, currentImages, deletedImages);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error editing post: ', error.message);
      toast.error(
        `Error ${formFunctionMode === 'new' ? 'creating new ' : 'editing '}post: ${error}`,
        {
          theme: 'dark',
          draggable: true,
          draggablePercent: 60,
        }
      );
    }
  };

  // handle category change and update state
  const handleCategoryChange = (event) => {
    setCategoryInput(event.target.value);
  };

  const navigate = useNavigate();
  const handleNewPost = () => {
    navigate('/dashboard/new');
  };
  const handleDownloadCSV = () => {
    downloadInventoryCSV();
  };

  // ========================================================================
  // BEGINNING OF RETURN
  // ========================================================================

  return (
    <>
      <div className="form-wrapper">
        {' '}
        <aside className="admin-panel" style={{ marginTop: '70px' }}>
          <section className="admin-panel-section ">
            <div className="button-container">
              {' '}
              <h4>Product Management</h4>
              {!restricted ? (
                <Button title="New Post" size="medium" variant="outlined" onClick={handleNewPost}>
                  New Post
                </Button>
              ) : (
                <span>New Post Disabled </span>
              )}
              <Button
                variant="outlined"
                className="new-link download-button"
                title="Download Inventory CSV"
                onClick={handleDownloadCSV}
              >
                Inventory
              </Button>
              <div className="temp-fix"></div>
              <h4>Subscription Management</h4>
              <DashboardSubMgt />
            </div>
          </section>
        </aside>
        <form className="new-post-form" onSubmit={handleFormSubmit} encType="multipart/form-data">
          <Typography
            variant="h1"
            id="form-title-header"
            sx={{
              fontSize: '1.8rem',
              fontWeight: 'bold',
              margin: 0,
              padding: 0,
              justifySelf: 'center',
              fontFamily: 'Shadows Into Light, cursive',
              letterSpacing: '2px',
              gridRow: '1',
              gridColumn: '1 / 3',
            }}
          >
            {newOrEdit}
          </Typography>
          <div className="desk-cat-input">
            <br />
            <Select
              id="category"
              value={categoryInput ? categoryInput : 'Choose category'}
              onChange={handleCategoryChange}
              className="image-input shadow-border"
              required
              sx={{ padding: '0' }}
            >
              <MenuItem value="Choose category" disabled>
                Choose category
              </MenuItem>
              <MenuItem value="Beads">Beads</MenuItem>
              <MenuItem value="Blunt Tips">Blunt Tips</MenuItem>
              <MenuItem value="Bubblers">Bubblers</MenuItem>
              <MenuItem value="Collabs">Collabs</MenuItem>
              <MenuItem value="Cups">Cups</MenuItem>
              <MenuItem value="Dry Pieces">Dry Pieces</MenuItem>
              <MenuItem value="Goblets">Goblets</MenuItem>
              <MenuItem value="Iso Stations">Iso Stations</MenuItem>
              <MenuItem value="Marbles">Marbles</MenuItem>
              <MenuItem value="Pendants">Pendants</MenuItem>
              <MenuItem value="Recyclers">Recyclers</MenuItem>
              <MenuItem value="Rigs">Rigs</MenuItem>
              <MenuItem value="Slides">Slides</MenuItem>
              <MenuItem value="Spinner Caps">Spinner Caps</MenuItem>
              <MenuItem value="Terp Pearls">Terp Pearls</MenuItem>
              <MenuItem value="Tubes">Tubes</MenuItem>
              <MenuItem value="Misc">Misc</MenuItem>
            </Select>
          </div>
          <div className="desk-title-input">
            <br />
            <TextField
              required
              maxLength={50}
              placeholder="Enter title"
              className="image-input"
              type="text"
              name="title"
              value={titleInput}
              onChange={(e) => setTitleInput(e.target.value)}
            ></TextField>
          </div>
          <div className="desk-desc-input">
            <br />
            <TextField
              required
              maxLength={350}
              multiline
              rows={2}
              placeholder="Enter description"
              className="image-input description shadow-border"
              name="description"
              value={descriptionInput}
              onChange={(e) => setDescriptionInput(e.target.value)}
            ></TextField>
          </div>

          <div className="desk-price-input-wrapper">
            <div className="desk-price-input">
              <br />{' '}
              <TextField
                required
                placeholder="Enter price"
                className="image-input input-with-dollar-sign"
                type="number"
                step="1"
                name="price"
                value={priceInput}
                onChange={(e) => setPriceInput(e.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              >
                {' '}
                $
              </TextField>
            </div>
          </div>

          <FormControl
            component="fieldset"
            className="sold-radio-group "
            sx={{ marginTop: '10px' }}
          >
            <RadioGroup
              className=".sold-radio-group"
              aria-label="sold status"
              name="sold-status-group"
              value={soldInput ? soldInput : 'false'}
              // onChange={(event) => setSoldInput(event.target.value === 'true')}
              row
            >
              <FormControlLabel
                value="true"
                checked={soldInput === true}
                onChange={() => setSoldInput(true)}
                control={<Radio />}
                label="Sold"
              />
              <FormControlLabel
                value="false"
                checked={soldInput === false}
                onChange={() => setSoldInput(false)}
                control={<Radio />}
                label="Available"
              />
            </RadioGroup>
          </FormControl>

          {!restricted ? (
            <Box {...getRootProps()} className="dropzone" sx={{ marginTop: '10px' }}>
              <input {...getInputProps()} />
              <label className="file-upload-label">
                {files.length === 0
                  ? 'Choose images or videos'
                  : `${files.length} file${files.length > 1 ? 's' : ''} selected`}
              </label>
            </Box>
          ) : (
            <Typography>Image Select Disabled</Typography>
          )}
          {/* {thumbs} */}
          {!restricted ? thumbs : ''}

          <Box className="btn-container" sx={{ marginBottom: '40px' }}>
            <Button
              variant="outlined"
              onClick={() => navigate('/dashboard')}
              sx={{ margin: '0px 15px' }}
            >
              Cancel
            </Button>

            {!restricted ? (
              <Button
                type="submit"
                variant="outlined"
                disabled={loading || (files.length === 0 && currentImages.length === 0)}
              >
                {loading ? 'Uploading...' : 'Upload'}
              </Button>
            ) : (
              <Typography>Upload Disabled</Typography>
            )}
          </Box>
        </form>
      </div>
    </>
  );
}
