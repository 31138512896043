import { useState } from 'react';
import { useEffect } from 'react';
import { fetchProducts } from '../services/fetch-products.js';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export function useProducts() {
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [productError, setProductError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingProducts(true);
        const data = await fetchProducts();
        if (data === null) {
          // Handle unauthorized access silently
          setProductError('Unauthorized access - please log in.');
          navigate('/auth/sign-in');
          return;
        }
        setProducts(data);
        setLoadingProducts(false);
      } catch (e) {
        if (e.response && e.response.status === 401) {
          // eslint-disable-next-line no-console
          console.error(e.response.data.message);
        }
        setProductError(e.message);
        // Trigger a Toastify error notification
        toast.error(`Error fetching products: ${e}`, {
          theme: 'dark',
          draggable: true,
          draggablePercent: 60,
          toastId: 'useProducts-1',
          autoClose: false,
        });
      }
    };
    fetchData();
  }, []);

  return {
    products,
    setProducts,
    loadingProducts,
    setLoadingProducts,
    productError,
    setProductError,
  };
}
