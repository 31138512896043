import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography, CircularProgress, Avatar } from '@mui/material';
import './DashboardSubMgt.css';

import useLoadingState from '../../../../context/LoadingContext.js';
import useStripeCustomer from '../../../../hooks/useStripeCustomer.js';
import { fetchStripeCustomerPortal } from '../../../../services/stripe.js';
import FlamePipe from '../../../../components/FlamePipe/FlamePipe.js';
import { usePosts } from '../../../../hooks/usePosts.js';
import { useAuthContext } from '../../../../context/AuthContext.js';
import { useStripeContext } from '../../../../context/StripeContext.js';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Dashboard() {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const { isAuthenticated } = useAuthContext();
  const { customerId } = useStripeContext();
  const { fetchBillingPeriod } = useStripeCustomer();
  const navigate = useNavigate();
  const { pageLoading, setPageLoading } = useLoadingState();
  const { restricted } = usePosts();

  useEffect(() => {
    if (isAuthenticated && customerId) {
      const getBillingPeriod = async () => {
        try {
          if (!isAuthenticated) {
            navigate('/auth/sign-in');
            return;
          }

          const { startDate, endDate } = await fetchBillingPeriod();

          // If either date is undefined or null, then don't proceed further.
          if (!startDate || !endDate) return;

          const billingStart = new Date(startDate * 1000);
          const billingEnd = new Date(endDate * 1000);
          setStartDate(billingStart.toLocaleDateString());
          setEndDate(billingEnd.toLocaleDateString());
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching billing period:', error.message);
          toast.error(`Error fetching billing period: ${error}`, {
            theme: 'dark',
            draggable: true,
            draggablePercent: 60,
            toastId: 'dashboardSubMgt-1',
            autoClose: false,
          });
        }
      };

      getBillingPeriod();
    }
  }, [isAuthenticated, navigate, customerId, fetchBillingPeriod]);

  const handleOpenCustomerPortal = async () => {
    setPageLoading(true);
    const data = await fetchStripeCustomerPortal({ customerId });

    return data;
  };
  const oldCustomerNewSubscription = () => {
    navigate('/subscription');
  };
  return (
    <>
      {!pageLoading ? (
        <Box className={'dashboard-wrapper'}>
          <Container className={'dashboard-container'} maxWidth="xl">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: (theme) => theme.spacing(2),
                border: 'solid 2px',
                borderColor: (theme) => theme.palette.primary.dark,
              }}
            >
              {!restricted ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '300px',
                    borderRadius: '5px',
                    backgroundColor: (theme) => {
                      theme.palette.primary.light;
                    },
                  }}
                >
                  <Box display={'flex'}>
                    <Box className={''}>
                      <CheckBoxOutlinedIcon
                        sx={{
                          backgroundColor: 'green',
                        }}
                      />
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: 'center',
                        alignContent: 'center',
                        paddingLeft: '10px',
                        color: (theme) => theme.palette.primary.light,
                      }}
                    >
                      Your subscription is active
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: '16px',
                      justifyContent: 'space-between',
                      margin: '0 8px',
                    }}
                  >
                    {' '}
                    <Typography variant="body1">Subscription Started On:</Typography>
                    <Typography variant="body2">{startDate}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: '16px',
                      margin: '0 8px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="body1">Subscription Will End On:</Typography>
                    <Typography variant="body2">{endDate}</Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{ display: 'flex', backgroundColor: 'yellow', color: 'black', width: '100%' }}
                >
                  <Box className={'expired-subscription'}></Box>
                  <Typography variant="body2" textAlign={'center'} padding={'10px'}>
                    Your subscription is no longer active!
                  </Typography>
                </Box>
              )}

              {!restricted ? (
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleOpenCustomerPortal}
                  startIcon={<AccountBalanceOutlinedIcon />}
                  sx={{ width: '300px', borderRadius: '0px' }}
                >
                  Subscription Billing
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  onClick={oldCustomerNewSubscription}
                  sx={{ width: '300px' }}
                  startIcon={<AccountBalanceOutlinedIcon />}
                >
                  Renew Subscription
                </Button>
              )}
            </Box>
          </Container>
        </Box>
      ) : (
        <FlamePipe />
      )}
    </>
  );
}
