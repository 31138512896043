const BASE_URL = process.env.REACT_APP_BASE_URL;

/* Data functions */

// get all posts from database and display on dashboard page
export async function fetchPosts() {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/dashboard`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    // Check if the server responded with a non-200 status
    const data = await resp.json();
    if (!resp.ok) {
      if (resp.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error fetching posts: ${data.message || `Status ${resp.status}`}`);
      throw new Error(`Error fetching posts: ${data.message || `Status ${resp.status}`}`);
    }

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error in fetchPosts:', error.message);
    throw error;
  }
}

// create new post in database
export async function postPost(
  title,
  description,
  image_url,
  category,
  price,
  public_id,
  num_imgs,
  sold
) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/dashboard`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        title,
        description,
        image_url,
        category,
        price,
        public_id,
        num_imgs,
        sold,
      }),
      credentials: 'include',
    });

    // Check if the server responded with a non-200 status
    const msg = await resp.json();
    if (!resp.ok) {
      if (resp.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error creating post: ${msg.message || `Status ${resp.status}`}`);
      throw new Error(`Error creating post: ${msg.message || `Status ${resp.status}`}`);
    }

    return msg;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

//upload image urls and public ids to db
export async function postAddImages(imageFiles, id) {
  try {
    const formData = new FormData();
    formData.append('image_urls', JSON.stringify(imageFiles?.map((image) => image.secure_url)));
    formData.append(
      'image_public_ids',
      JSON.stringify(imageFiles?.map((image) => image.public_id))
    );
    formData.append(
      'resource_types',
      JSON.stringify(imageFiles?.map((image) => image.resource_type))
    );
    // Append the id to the formData
    formData.append('id', id);
    const resp = await fetch(`${BASE_URL}/api/v1/dashboard/images`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });

    // Check if the server responded with a non-200 status
    const msg = await resp.json();
    if (!resp.ok) {
      if (resp.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error adding images: ${msg.message || `Status ${resp.status}`}`);
      throw new Error(`Error adding images: ${msg.message || `Status ${resp.status}`}`);
    }
    return msg;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

// function for transferring main image from gallery_posts to post_imgs
export async function transferProductPic(postId) {
  // TODO look into this... maybe the cause of the double img thing happening in create a post from quota tracking

  try {
    const resp = await fetch(`${BASE_URL}/api/v1/dashboard/transfer`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        postId,
      }),
      credentials: 'include',
    });

    // Check if the server responded with a non-200 status
    const msg = await resp.json();
    if (!resp.ok) {
      if (resp.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error transferring product images: ${msg.message || `Status ${resp.status}`}`);
      throw new Error(
        `Error transferring product images: ${msg.message || `Status ${resp.status}`}`
      );
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error transferring images');
  }
}

// delete single post from database
export async function deleteById(post_id) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/dashboard/${post_id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    if (!resp.ok) {
      if (resp.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error fetching posts: Status ${resp.status}`);
      throw new Error(`Error fetching posts: Status ${resp.status}`);
    }
    return resp;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

// edit post called from EditPost
export async function updatePost(id, post) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/dashboard/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, post }),
      credentials: 'include',
    });

    // Check if the server responded with a non-200 status
    const msg = await resp.json();
    if (!resp.ok) {
      if (resp.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error updating post: ${msg.message || `Status ${resp.status}`}`);
      throw new Error(`Error updating post: ${msg.message || `Status ${resp.status}`}`);
    }

    return msg;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

// return post detail (no image urls aside from the first one)
export async function getPostDetail(id) {
  try {
    const resp = await fetch(`${BASE_URL}/api/v1/dashboard/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    // Check if the server responded with a non-200 status

    const postData = await resp.json();
    if (!resp.ok) {
      if (resp.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      throw new Error(`${postData.message || `Status ${resp.status}`}`);
    }

    return postData;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

// Cloudinary functions ---------------------------------------

//  Upload image files to Cloudinary
export const uploadImagesAndCreatePost = async (imageFiles, formFunctionMode) => {
  const formData = new FormData();
  imageFiles.forEach((file) => formData.append('imageFiles', file));
  try {
    const response = await fetch(`${BASE_URL}/api/v1/dashboard/upload`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });

    // Check if the server responded with a non-200 status
    const data = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error uploading images: ${data.message || `Status ${response.status}`}`);
      throw new Error(`Error uploading images: ${data.message || `Status ${response.status}`}`);
    }

    const result = data.files;
    const image_urls = result.map((image) => image.secure_url);
    const public_ids = result.map((image) => image.public_id);

    let additionalImages = [];
    let newPost;
    let newImages = [];
    let editedPost;

    // create additionalImages WITH the default image
    if (formFunctionMode === 'new') {
      additionalImages = result.map((image) => ({
        public_id: image.public_id,
        secure_url: image.secure_url,
        resource_type: image.resource_type,
      }));

      // create new post object with default image url and public id,
      //  and any additional images
      newPost = {
        image_url: image_urls[0],
        public_id: public_ids[0],
        additionalImages,
      };

      return newPost;
    } else {
      newImages = result.map((image) => ({
        secure_url: image.secure_url,
        public_id: image.public_id,
        resource_type: image.resource_type,
      }));
      // create edited post object with new images
      editedPost = {
        newImages,
        additionalImages,
      };
      return editedPost;
    }

    // return newPost;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

// Upload user avatar image file to Cloudinary
export const uploadAvatarImage = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/profile/avatar-upload`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });

    // Check if the server responded with a non-200 status
    const result = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(
        `Error uploading avatar image: ${result.message || `Status ${response.status}`}`
      );
      throw new Error(
        `Error uploading avatar image: ${result.message || `Status ${response.status}`}`
      );
    }

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};
// Upload customer logo  image file to Cloudinary
export const uploadLogoImage = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/profile/logo-upload`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });

    // Check if the server responded with a non-200 status
    const result = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error uploading logo image: ${result.message || `Status ${response.status}`}`);
      throw new Error(
        `Error uploading logo image: ${result.message || `Status ${response.status}`}`
      );
    }

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

export const deleteImage = async (public_id, resource_type) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/dashboard/delete`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_id: public_id, resource_type: resource_type }),
    });

    // Check if the server responded with a non-200 status
    const result = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error deleting image: ${result.message || `Status ${response.status}`}`);
      throw new Error(`Error deleting image: ${result.message || `Status ${response.status}`}`);
    }
    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

// Delete avatar image from Cloudinary
export const deleteAvatarImage = async (publicId, resourceType) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/profile/avatar-delete`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_id: publicId, resource_type: resourceType }),
    });

    // Check if the server responded with a non-200 status
    const result = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(
        `Error deleting avatar image: ${result.message || `Status ${response.status}`}`
      );
      throw new Error(
        `Error deleting avatar image: ${result.message || `Status ${response.status}`}`
      );
    }

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

// Delete logo image from Cloudinary
export const deleteLogoImage = async (publicId, resourceType) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/profile/logo-delete`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_id: publicId, resource_type: resourceType }),
    });

    // Check if the server responded with a non-200 status
    const result = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error deleting logo image: ${result.message || `Status ${response.status}`}`);
      throw new Error(
        `Error deleting logo image: ${result.message || `Status ${response.status}`}`
      );
    }

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

// Image data in our DB ---------------------------------------

// delete single image data from db
export const deleteImageData = async (id, public_id) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/dashboard/image/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_id: public_id }),
    });

    // Check if the server responded with a non-200 status
    const result = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error deleting image data: ${result.message || `Status ${response.status}`}`);
      throw new Error(
        `Error deleting image data: ${result.message || `Status ${response.status}`}`
      );
    }

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

// Get additional image urls from in db
export const getAdditionalImageUrlsPublicIds = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/dashboard/urls/${id}`, {
      method: 'GET',
      credentials: 'include',
    });
    // Check if the server responded with a non-200 status
    const result = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(
        `Error fetching additional image urls and public ids: ${
          result.message || `Status ${response.status}`
        }`
      );
      throw new Error(
        `Error fetching additional image urls and public ids: ${
          result.message || `Status ${response.status}`
        }`
      );
    }
    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

// Public routes for gallery ---------------------------------------

// Public route for fetch all posts
export const fetchGalleryPosts = async () => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/main-gallery`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const result = await response.json();
    if (!response.ok) {
      // eslint-disable-next-line no-console
      console.error(
        `Error fetching gallery posts: ${result.message || `Status ${response.status}`}`
      );
      throw new Error(
        `Error fetching gallery posts: ${result.message || `Status ${response.status}`}`
      );
    }

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

// Public route for post detail
export async function getGalleryPostDetail(id) {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/gallery-posts/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const msg = await response.json();
    if (!response.ok) {
      // eslint-disable-next-line no-console
      console.error(
        `Error fetching gallery post detail: ${msg.message || `Status ${response.status}`}`
      );
      throw new Error(
        `Error fetching gallery post detail: ${msg.message || `Status ${response.status}`}`
      );
    }

    return msg;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

export const getAdditionalImageUrlsPublicIdsGallery = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/gallery-posts/urls/${id}`, {
      method: 'GET',
    });

    const result = await response.json();
    if (!response.ok) {
      // eslint-disable-next-line no-console
      console.error(
        `Error fetching additional images urls and public ids: ${
          result.message || `Status ${response.status}`
        }`
      );
      throw new Error(
        `Error fetching additional images urls and public ids: ${
          result.message || `Status ${response.status}`
        }`
      );
    }

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

// TODO make equivalent for other tabs...
// Download inventory CSV file ---------------------------------------
export async function downloadInventoryCSV() {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/dashboard/download-inventory-csv`, {
      credentials: 'include',
    });

    const blob = await response.blob();

    // Check if the server responded with a non-200 status
    if (!response.ok) {
      if (response.status === 401) {
        return null; // Return null to indicate unauthorized without throwing
      }

      // eslint-disable-next-line no-console
      console.error(`Error downloading CSV file: ${blob.message || `Status ${response.status}`}`);
      throw new Error(`Error downloading CSV file: ${blob.message || `Status ${response.status}`}`);
    }

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'inventory.csv';
    document.body.appendChild(a); // Append the anchor to body or it may not work in some browsers
    a.click();
    a.remove(); // Clean up
    window.URL.revokeObjectURL(url);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error during fetch or download:', error);
    throw error;
  }
}
