import React, { useEffect } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useAuthContext } from '../../context/AuthContext.js';
import { useNavigate } from 'react-router-dom';
import { useStripeContext } from '../../context/StripeContext.js';
import FlamePipe from '../FlamePipe/FlamePipe.js';
import AnimatedEllipsis from '../AnimatedEllipsis/AnimatedEllipsis.js';

export default function LoginLanding() {
  const { isAuthenticated, email } = useAuthContext();
  const { customerId, loadingCustomerId } = useStripeContext();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/auth/sign-in');
      return;
    }
    setTimeout(() => {
      if (customerId && !loadingCustomerId) {
        navigate('/dashboard');
        return;
      } else {
        navigate('/subscription');
        return;
      }
    }, 2000);
  }, [customerId, loadingCustomerId, isAuthenticated, navigate]);

  return (
    <>
      <Box
        sx={{
          marginTop: '200px',
          textAlign: 'center',
          padding: '20px',
          justifySelf: 'center',
        }}
      >
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '1.2rem',
            width: isMobile ? '100' : '50%',
            margin: 'auto',
          }}
        >
          Logging{` ${email} `}in
          <AnimatedEllipsis />
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <FlamePipe />
        </Box>
      </Box>
    </>
  );
}
