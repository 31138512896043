import React, { useEffect, useState } from 'react';
import userDefaultImage from './../../assets/user.png';
import instagram from './../../assets/social-icons/instagram.png';
import facebook from './../../assets/social-icons/facebook.png';
import tiktok from './../../assets/social-icons/tiktok.png';
import FlamePipe from '../FlamePipe/FlamePipe.js';
import {
  Button,
  Grid,
  Avatar,
  Typography,
  TextField,
  Card,
  CardContent,
  Link,
  Box,
  useMediaQuery,
} from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import {
  deleteAvatarImage,
  deleteLogoImage,
  uploadAvatarImage,
  uploadLogoImage,
} from '../../services/fetch-utils.js';
import { useQuery } from '../../context/QueryContext.js';

import Tabs from '../Tabs/Tabs.js';
import { useProfileContext } from '../../context/ProfileContext.js';
import { useAuthContext } from '../../context/AuthContext.js';
import { useStripeContext } from '../../context/StripeContext.js';
import { useProfile } from '../../hooks/useProfile.js';
import { useMemo } from 'react';
import '../PostCard/PostCard.css';
import './Profile.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useTheme } from '@emotion/react';
export default function Profile() {
  const {
    profile,
    bizProfile,
    // profileLoading,
    error,
    fetchProfile,
    updateProfile,
    updateBizProfile,
    socialMediaLinks,
    setSocialMediaLinks,
    editedProfile,
    setEditedProfile,
    setProfileUpdated,
    setLogoUrl,
    profileLoading,
  } = useProfileContext();

  const navigate = useNavigate();

  // const { setCurrentCustomerId, setNewPostCreated } = useQuery(); // setNewPostCreated triggers a rerender- or did so that gallery updated avatars I believe
  const { setNewPostCreated } = useQuery();
  const { customerId } = useStripeContext();
  const { user, isAuthenticated } = useAuthContext();
  const { sub } = useParams();
  // const { restricted } = usePosts();
  // console.log('restricted', restricted);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.down('lg'));

  const isViewingOwnProfile = isAuthenticated && user === sub;
  const profileHookData = useProfile(sub);

  const finalProfile = isViewingOwnProfile ? profile : profileHookData.profile;
  // const finalBizProfile = isViewingOwnProfile ? bizProfile : profileHookData.bizProfile;
  const finalBizProfile = useMemo(() => {
    return isViewingOwnProfile ? bizProfile : profileHookData.bizProfile;
  }, [isViewingOwnProfile, bizProfile, profileHookData.bizProfile]);

  const finalSocialMediaLinks = useMemo(() => {
    return isViewingOwnProfile ? socialMediaLinks : profileHookData.profile?.socialMediaLinks || {};
  }, [isViewingOwnProfile, socialMediaLinks, profileHookData.profile?.socialMediaLinks]);

  //
  //

  //
  //

  const [isEditing, setIsEditing] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [userTempImage, setUserTempImage] = useState(finalProfile?.imageUrl || userDefaultImage);
  const [logoPreview, setLogoPreview] = useState(finalBizProfile?.logoImageUrl || userDefaultImage);

  const splitProfileData = (combinedProfile) => {
    return {
      userProfile: {
        imageUrl: combinedProfile.imageUrl,
        publicId: combinedProfile.publicId,
        firstName: combinedProfile.firstName,
        lastName: combinedProfile.lastName,
        bio: combinedProfile.bio,
        socialMediaLinks: {
          instagram: combinedProfile.socialMediaLinks?.instagram,
          facebook: combinedProfile.socialMediaLinks?.facebook,
          tiktok: combinedProfile.socialMediaLinks?.tiktok,
        },
      },
      bizProfile: {
        displayName: combinedProfile.displayName,
        websiteUrl: combinedProfile.websiteUrl,
        logoImageUrl: combinedProfile.logoImageUrl,
        logoPublicId: combinedProfile.logoPublicId,
      },
    };
  };

  const handleAvatarUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const localImageUrl = URL.createObjectURL(file);
    setUserTempImage(localImageUrl);
    setEditedProfile((prev) => ({ ...prev, imageUrl: localImageUrl }));
    setAvatarFile(file);
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const localImageUrl2 = URL.createObjectURL(file);
    setLogoPreview(localImageUrl2);

    setEditedProfile((prev) => ({ ...prev, logoImageUrl: localImageUrl2 }));
    setLogoFile(file);
  };

  const handleEdit = (field, value) => {
    setEditedProfile((prev) => ({ ...prev, [field]: value }));
  };

  const handleSocialMediaLinkChange = (platform, value) => {
    setEditedProfile((prev) => {
      const updatedProfile = {
        ...prev,
        socialMediaLinks: {
          ...prev.socialMediaLinks,
          [platform]: value,
        },
      };

      return updatedProfile;
    });
  };

  // Submit form to update profile
  // Submit form to update profile
  const handleSaveChanges = async (e) => {
    setIsUpdating(true);
    e.preventDefault();

    const { userProfile, bizProfile } = splitProfileData(editedProfile);

    // Delete old avatar image from Cloudinary first if a new avatar is chosen
    if (avatarFile && finalProfile.publicId) {
      await deleteAvatarImage(finalProfile.publicId);
    }

    // If an avatar image is selected, send it to the server
    if (avatarFile) {
      const formData = new FormData();
      formData.append('avatar', avatarFile);

      const { publicId, secureUrl } = await uploadAvatarImage(formData);
      userProfile.publicId = publicId;
      userProfile.imageUrl = secureUrl;
    }

    // Update user profile
    updateProfile(userProfile);

    // Check if bizProfile exists before attempting to update the logo
    if (logoFile && bizProfile) {
      if (bizProfile.logoPublicId) {
        await deleteLogoImage(bizProfile.logoPublicId);
      }

      const formData = new FormData();
      formData.append('logo', logoFile);

      const { publicId, secureUrl } = await uploadLogoImage(formData);
      bizProfile.logoPublicId = publicId;
      bizProfile.logoImageUrl = secureUrl;

      setLogoUrl(secureUrl);
    }

    // Update bizProfile if it exists and has changes
    if (bizProfile && Object.values(bizProfile).some((value) => value)) {
      updateBizProfile(bizProfile);
    }

    setTimeout(() => {
      setProfileUpdated((prevState) => !prevState);
      setNewPostCreated((prevState) => !prevState); // this is state that was created for NewPost to trigger a re-render of the gallery, but it works here too to re-render the gallery after a profile update
      setIsEditing(false);
      setIsUpdating(false);
    }, 500);
  };

  const handleBioChange = (value) => {
    if (value.length <= 255) {
      handleEdit('bio', value);
    } else {
      // Optionally, you can give a feedback to the user,
      // like a snackbar or toast notification, to inform
      // them about the character limit.
      // console.warn('Bio exceeds the maximum character limit.');
    }
  };

  // if (loadingAuth || profileLoading) {
  //   return <FlamePipe />;
  // }

  useEffect(() => {
    if (profile === null) {
      navigate('/not-found');
    }
  });

  useEffect(() => {
    setUserTempImage(finalProfile?.imageUrl || userDefaultImage);
  }, [finalProfile?.imageUrl]);

  useEffect(() => {
    setLogoPreview(finalBizProfile?.logoImageUrl || userDefaultImage);
  }, [finalBizProfile?.logoImageUrl]);

  useEffect(() => {
    setUserTempImage(finalProfile?.imageUrl);
  }, [finalProfile?.imageUrl]);

  useEffect(() => {
    setLogoPreview(finalBizProfile?.logoImageUrl);
  }, [finalBizProfile?.logoImageUrl]);

  useEffect(() => {
    if (finalProfile && finalBizProfile) {
      const newLinks = {};

      // Define all possible social media keys
      const possibleLinks = ['instagram', 'facebook', 'tiktok']; // Add more as needed

      // Iterate and set only the existing ones
      possibleLinks.forEach((key) => {
        if (finalProfile?.socialMediaLinks && finalProfile.socialMediaLinks[key]) {
          newLinks[key] = finalProfile.socialMediaLinks[key];
        }
      });

      const combinedProfile = { ...finalProfile, ...finalBizProfile };
      setEditedProfile(combinedProfile);
      setSocialMediaLinks(newLinks);
    }
  }, [
    finalProfile,
    finalBizProfile,
    customerId,
    // setCurrentCustomerId, // this does nothing
    setSocialMediaLinks,
    setEditedProfile,
  ]);

  useEffect(() => {
    if (sub !== null) {
      fetchProfile(sub);
    }
  }, [sub]);

  const renderProfileHeader = () => {
    if (finalBizProfile) {
      return finalBizProfile.displayName;
    }

    if (finalProfile?.firstName) {
      return `${finalProfile.firstName}'s Profile Page`;
    }
    return "New Member's Profile Page";
  };

  let toast = {};
  if (error === 'Failed to fetch') {
    toast.message = 'Something went wrong fetching this profile. Please try again later.';
  }

  return isUpdating || profileLoading || error ? (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        margin: '200px 0 0 0',
      }}
    >
      <FlamePipe className="loading-detail-2" />
    </Box>
  ) : (
    <div style={{ padding: '0px', maxWidth: '1200px', paddingTop: '70px', margin: 'auto' }}>
      {profile && <h1>{renderProfileHeader()}</h1>}

      <Grid container spacing={3}>
        {/* Left column: Avatar & Logo */}
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <Card
            variant="outlined"
            sx={{
              borderWidth: '1px',
              borderColor: (theme) => theme.palette.primary.light,
              borderRadius: (theme) => theme.spacing(1),
              minWidth: '250px',

              // display: 'grid',
              // justifyContent: 'start',
              // flexDirection: 'column',
              // justifyItems: 'flex-end',
              // alignItems: 'flex-end',
            }}
          >
            {isViewingOwnProfile && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                {' '}
                <Box
                  sx={{
                    padding: '0px',
                    display: 'flex',
                    justifyContent: isEditing ? 'space-between' : 'flex-end',
                  }}
                >
                  {' '}
                  {isViewingOwnProfile && isEditing && (
                    <Button variant="contained" color="primary" onClick={handleSaveChanges}>
                      {isUpdating ? 'Updating...' : 'Save Changes'}
                    </Button>
                  )}
                  {isEditing ? (
                    <CancelOutlinedIcon
                      sx={{ cursor: 'pointer', fontSize: 'large', margin: '8px 8px 0 0' }}
                      onClick={() => setIsEditing(!isEditing)}
                    />
                  ) : (
                    <EditOutlinedIcon
                      sx={{ cursor: 'pointer', fontSize: 'large', margin: '8px 8px 0 0' }}
                      onClick={() => setIsEditing(!isEditing)}
                    />
                  )}
                </Box>
              </Box>
            )}
            <CardContent
              sx={{
                display: 'flex',
                padding: '10px',
                flexDirection: isMobile || !isTablet ? 'column' : 'row',
              }}
            >
              {/* Display Name */}
              {finalBizProfile && (
                <div style={{ margin: '0 0' }}>
                  {isEditing && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Display Name"
                      value={editedProfile?.displayName || ''}
                      onChange={(e) => handleEdit('displayName', e.target.value)}
                    />
                  )}
                </div>
              )}
              {/* Business Logo & User Avatar */}
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: isMobile || !isTablet || isEditing ? '1fr' : '1fr 1fr 1fr',
                  gridTemplateRows: '1fr',
                  gap: '10px',
                  justifyItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: isMobile || !isTablet ? 'wrap' : '',
                    gap: '10px',
                    width: '100%',
                    flexDirection: isMobile || !isTablet ? 'column' : 'row',
                    justifyContent: 'space-between',
                    position: 'relative',
                  }}
                >
                  {/* Business Logo */}
                  {finalBizProfile && isEditing ? (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                          padding: '10px',
                          margin: '0px auto 0 auto',
                          borderWidth: '1px',
                          borderStyle: 'solid',
                          borderColor: (theme) => theme.palette.primary.light,
                          width: '180px',
                        }}
                      >
                        <Box
                          component="img"
                          src={logoPreview || userDefaultImage}
                          style={{
                            width: '100px',
                            height: '100px',
                            objectFit: 'cover',
                          }}
                        />
                        {/* {' '} */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                          {/* Custom Button */}
                          <Button
                            variant="contained"
                            component="label"
                            sx={{ padding: '10px 20px', backgroundColor: 'green', color: 'white' }}
                          >
                            Upload Logo
                            <input type="file" onChange={handleLogoUpload} hidden />
                          </Button>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    finalBizProfile && (
                      <Box
                        component="img"
                        src={finalBizProfile?.logoImageUrl || userDefaultImage}
                        sx={{
                          width: '200px',
                          height: '200px',
                          margin: 'auto',
                          boxShadow: '2px 2px 15px 2px #FFFFFF2a',
                        }}
                      />
                    )
                  )}

                  {/* User Avatar */}
                  {isEditing ? (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                          padding: '10px',
                          margin: '0px auto 0 auto',
                          borderWidth: '1px',
                          borderStyle: 'solid',
                          borderColor: (theme) => theme.palette.primary.light,
                          width: '180px',
                        }}
                      >
                        <Box
                          component="img"
                          src={
                            avatarFile
                              ? URL.createObjectURL(avatarFile)
                              : finalProfile?.imageUrl || userDefaultImage
                          }
                          style={{
                            width: '100px',
                            height: '100px',
                            objectFit: 'cover',
                          }}
                        />{' '}
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                          {/* Custom Button */}
                          <Button
                            variant="contained"
                            component="label"
                            sx={{ padding: '10px 20px', backgroundColor: 'green', color: 'white' }}
                          >
                            Upload Avatar
                            <input type="file" name="avatar" onChange={handleAvatarUpload} hidden />
                          </Button>

                          {/* File name display */}
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <Avatar
                      src={finalProfile?.imageUrl || userDefaultImage}
                      sx={{
                        width: '70px',
                        height: '70px',
                        margin: isMobile || !isTablet ? 'auto ' : '0px',
                        borderRadius: '50%',
                        border: '1px solid black',
                        // borderColor: (theme) => theme.palette.primary.dark,
                        position: isMobile || !isTablet ? '' : 'absolute',
                        top: isMobile || !isTablet ? '-50px' : '150px',
                        left: isMobile || !isTablet ? '85px' : '150px',
                      }}
                    />
                  )}
                </Box>
                {/* First Name & Last Name */}
                <div style={{ display: 'flex', marginTop: '10px' }}>
                  {isEditing ? (
                    <>
                      <TextField
                        variant="outlined"
                        label="First Name"
                        value={editedProfile?.firstName || ''}
                        onChange={(e) => handleEdit('firstName', e.target.value)}
                        style={{ marginRight: '5px' }}
                      />

                      <TextField
                        variant="outlined"
                        label="Last Name"
                        value={editedProfile?.lastName || ''}
                        onChange={(e) => handleEdit('lastName', e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      <Typography variant="body1" display="inline" sx={{ marginRight: '5px' }}>
                        {finalProfile?.firstName}
                      </Typography>
                      <Typography variant="body1" display="inline">
                        {finalProfile?.lastName}
                      </Typography>
                    </>
                  )}
                </div>
                {/* Website Link */}
                {finalBizProfile && !isEditing && (
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: 'left',
                      marginTop: '10px;',
                      gridRowStart: isMobile || !isTablet ? '' : 1,
                      gridColumnStart: isMobile || !isTablet ? '' : 3,
                    }}
                  >
                    Website:{'  '}
                    <Link
                      href={`https://${finalBizProfile?.websiteUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bizProfileLink"
                    >
                      {finalBizProfile?.websiteUrl}
                    </Link>
                  </Typography>
                )}
                {/* Social Media Links */}
                {finalBizProfile && isEditing ? (
                  <>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Website"
                      value={editedProfile.websiteUrl || ''}
                      onChange={(e) => handleEdit('websiteUrl', e.target.value)}
                    />
                    <Typography variant="caption" textAlign={'left'}>
                      Socials:
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="instagram.com/"
                      value={editedProfile.socialMediaLinks?.instagram || ''}
                      onChange={(e) => handleSocialMediaLinkChange('instagram', e.target.value)}
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="facebook.com/"
                      value={editedProfile.socialMediaLinks?.facebook || ''}
                      onChange={(e) => handleSocialMediaLinkChange('facebook', e.target.value)}
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="tiktok.com/"
                      value={editedProfile.socialMediaLinks?.tiktok || ''}
                      onChange={(e) => handleSocialMediaLinkChange('tiktok', e.target.value)}
                    />
                  </>
                ) : (
                  <Box
                    marginTop={0}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      gridRowStart: isMobile || !isTablet ? '' : '1',
                      gridColumnStart: isMobile || !isTablet ? '' : '3',
                      marginTop: isMobile || !isTablet ? '' : '3rem',
                    }}
                  >
                    {finalSocialMediaLinks?.instagram && (
                      <Link
                        href={`https://www.instagram.com/${finalSocialMediaLinks.instagram}`}
                        target="_blank"
                      >
                        <Avatar src={instagram} sx={{ width: '70px', height: 'auto' }} />
                      </Link>
                    )}
                    {finalSocialMediaLinks?.facebook && (
                      <Link
                        href={`https://www.facebook.com/${finalSocialMediaLinks.facebook}`}
                        target="_blank"
                      >
                        <Avatar src={facebook} sx={{ width: '70px', height: 'auto' }} />
                      </Link>
                    )}
                    {finalSocialMediaLinks?.tiktok && (
                      <Link
                        href={`https://www.tiktok.com/${finalSocialMediaLinks.tiktok}`}
                        target="_blank"
                      >
                        <Avatar src={tiktok} sx={{ width: '70px', height: 'auto' }} />
                      </Link>
                    )}
                  </Box>
                )}
                {/* Contact Email */}
                {finalBizProfile && isEditing ? (
                  // Optional Email editing fields here
                  <></>
                ) : finalBizProfile?.email && finalBizProfile?.displayName ? (
                  <Typography
                    variant="body1"
                    sx={{
                      gridRowStart: isMobile || !isTablet ? '' : 2,
                      gridColumnStart: isMobile || !isTablet ? '' : 3,
                    }}
                  >
                    <Link href={`mailto:${finalBizProfile.email}`}>
                      {`Email ${finalBizProfile.displayName}`}
                    </Link>
                  </Typography>
                ) : finalBizProfile?.email && !finalBizProfile?.displayName ? (
                  <Typography
                    variant="body1"
                    sx={{
                      display: 'grid',
                      gridRowStart: isMobile || !isTablet ? '' : 1,
                      gridColumnStart: isMobile || !isTablet ? '' : 3,
                    }}
                  >
                    <Link href={`mailto:${finalBizProfile.email}`}>{`Email this artist`}</Link>
                  </Typography>
                ) : null}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Right column: Rest of the details */}
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <Card
            variant="outlined"
            sx={{
              borderWidth: '1px',
              borderColor: (theme) => theme.palette.primary.light,
              borderRadius: (theme) => theme.spacing(1),
              // boxSizing: 'border-box',
              // marginLeft: '1rem',
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
                gap: (theme) => theme.spacing(1),
              }}
            >
              {/* Bio */}
              {isEditing ? (
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Bio"
                  multiline
                  rows={4}
                  value={editedProfile.bio || ''}
                  onChange={(e) => handleBioChange(e.target.value)}
                  inputProps={{ maxLength: 255 }}
                  helperText={`${editedProfile.bio?.length || 0}/255`}
                />
              ) : (
                <Typography variant="body1" paragraph>
                  {finalProfile?.bio ||
                    'New user here!  Still getting everything set up- come back soon.'}
                </Typography>
              )}

              {/* {isViewingOwnProfile && isEditing && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveChanges}
                  // disabled={restricted ? restricted : isUpdating}
                >
                  {isUpdating ? 'Updating...' : 'Save Changes'}
                </Button>
              )} */}
            </CardContent>
          </Card>
          <Tabs />
        </Grid>
      </Grid>
    </div>
  );
}
