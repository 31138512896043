import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  useTheme,
  useMediaQuery,
  Modal,
  Box,
  IconButton,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import the CloseIcon
import { usePosts } from '../../../hooks/usePosts.js';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

// Assuming `products` is an array of product objects and `setSelectedProduct` is the function to set the current product to edit
const ProductGrid = ({
  products,
  setSelectedProduct,
  selectedProduct,
  handleEditClick,
  handleDeleteClick,
  filteredProducts,
  paginate,
  currentPage,
  indexOfLastProduct,
  productsPerPage,
}) => {
  const [dateTotals, setDateTotals] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const { restricted } = usePosts();
  const productTypeColors = {
    auction: '#391bfc78',
    'direct-sale': '#478dff78',
    inventory: '#AAAAAA',
    'prep-other': 'rgba(0, 255, 0, 0.247)',
  };

  // grab data from products for display
  useEffect(() => {
    const newDateTotals = {};
    products.forEach((product) => {
      const price = parseFloat(product.price) || 0;
      const dateStr = formatDate(product.date); // Use the same formatDate function

      if (!newDateTotals[dateStr]) {
        newDateTotals[dateStr] = 0;
      }
      newDateTotals[dateStr] += price;
    });

    setDateTotals(newDateTotals);
  }, [products]);

  const formatDate = (timestamp) => {
    timestamp = Number(timestamp);
    const date = new Date(timestamp);
    return date.toLocaleDateString();
  };

  const normalizeDate = (timestamp) => {
    const date = new Date(Number(timestamp));
    date.setHours(0, 0, 0, 0); // Normalize to the start of the day
    return date.getTime();
  };

  const isLastProductOfDate = (index) => {
    if (index < 0 || index >= products.length || !products[index]) {
      return false;
    }

    const currentProductDate = normalizeDate(products[index].date);

    if (index === products.length - 1) {
      return true;
    }

    const nextProductDate = normalizeDate(products[index + 1].date);

    return currentProductDate !== nextProductDate;
  };

  const isSameDayAsPrevious = (index) => {
    if (index <= 0) return false;

    const previousProductDate = normalizeDate(products[index - 1].date);
    const currentProductDate = normalizeDate(products[index].date);

    return previousProductDate === currentProductDate;
  };

  // This function assumes that products are sorted by date
  const getDayTotalDisplay = (product, index) => {
    // If this is the last product of the date, return the formatted date.
    if (isLastProductOfDate(index)) {
      const formattedDate = formatDate(product.date);
      const total = dateTotals[formattedDate]?.toLocaleString();

      return (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
          }}
        >
          <Typography variant="body1">{formattedDate}:</Typography>
          <Typography width={'100%'}>
            <Typography
              variant="span"
              sx={{
                color: 'text.secondary',
                marginRight: '8px',
                textAlign: 'right',
              }}
            >
              Day Total:
            </Typography>

            <Typography
              variant="span"
              sx={{
                color: total < 0 ? 'red' : 'inherit',
                // fontWeight: 'bold',
                fontSize: '1.1rem',
              }}
            >
              ${total}
            </Typography>
          </Typography>
        </Box>
      );
    }
    return null;
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const handleImageClick = (imageUrl) => {
    setModalImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Define a color index variable to keep track of the current color
  let currentColorIndex = -1;
  let lastProductDate = null;

  const getSequentialColor = (product, index) => {
    const currentProductDate = new Date(Number(product.date)).toDateString(); // Convert to a comparable string format

    if (currentProductDate !== lastProductDate) {
      currentColorIndex = (currentColorIndex + 1) % dayColors.length;
      lastProductDate = currentProductDate;
    }

    return dayColors[currentColorIndex];
  };

  const dayColors = [
    'rgba(0, 123, 255, 0.2)', // Light Blue
    'rgba(40, 167, 69, 0.2)', // Light Green
    'rgba(23, 162, 184, 0.2)', // Light Teal
    'rgba(65, 117, 125, 0.2)', // Light Grey (Neutral)
    'rgba(0, 123, 255, 0.2)', // Medium Blue
    'rgba(40, 167, 69, 0.2)', // Medium Green
    'rgba(23, 162, 184, 0.2)', // Medium Teal
  ];

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          backgroundColor: '#161616',
        }}
      >
        <Button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </Button>

        <Typography sx={{ margin: '0 15px' }}>
          Page {currentPage} of {Math.ceil(filteredProducts.length / productsPerPage)}
        </Typography>

        <Button
          onClick={() => paginate(currentPage + 1)}
          disabled={indexOfLastProduct >= filteredProducts.length}
        >
          Next
        </Button>
      </Box>

      <Grid container sx={{ margin: '0px 0px 0px 0px', width: '99%' }}>
        {products.map((product, index) => (
          <Grid
            item
            xs={12}
            key={product.id}
            sx={{
              marginBottom: '0px',
              transition: 'margin-bottom 0.2s', // This can remain for a smooth visual effect
            }}
          >
            <Card
              sx={{
                boxSizing: 'border-box',
                boxShadow: 'none',
                borderRadius: '0px',
                borderTopLeftRadius: isSameDayAsPrevious(index) ? '0px' : '8px', // Remove border-radius for grouped products
                borderTopRightRadius: isSameDayAsPrevious(index) ? '0px' : '8px',
                border: selectedProduct?.id === product.id ? '3px solid yellow' : 'none',
                margin: '0',
                padding: '0',
                background: 'none',
                backgroundColor: getSequentialColor(product, index),
                marginTop: isSameDayAsPrevious(index) ? '0px' : '15px', // Remove gap between same-day products
                transition: 'margin-bottom 0.2s', // Add transition for smooth visual effect
              }}
            >
              <CardContent
                sx={{
                  padding: '8px',
                  '&:last-child': { padding: '4px 2px 0px 2px' },
                }}
              >
                {isMobile ? (
                  // Mobile View Layout =================================================
                  <>
                    <Grid
                      item
                      // xs={12}
                      sx={{
                        display: 'grid',
                        gridTemplateRows: '1fr',
                        gridTemplateColumns: '1fr 2fr 2fr 5fr 22px',
                        // alignItems: 'start',
                        // justifyItems: 'center',
                        gap: '10px',
                        // width: '70%',
                      }}
                    >
                      {' '}
                      <Box
                        component="img"
                        sx={{
                          height: 60,
                          width: 60,
                          borderRadius: '5px',
                          cursor: 'pointer',
                        }}
                        src={product.image_url}
                        alt={product.title}
                        onClick={() => handleImageClick(product.image_url)}
                      />
                      <Box sx={{ minWidth: '105px' }}>
                        {' '}
                        <Typography variant="body1" sx={{ marginLeft: 1, textAlign: 'left' }}>
                          {product?.title
                            ? product.title.length > 12
                              ? `${product.title.slice(0, 10)}...`
                              : product.title
                            : 'No Title'}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: 'text.secondary', marginLeft: 1, textAlign: 'left' }}
                        >
                          {product.category}
                        </Typography>
                      </Box>
                      <Typography textAlign={'left'} sx={{ fontSize: '.9rem', marginTop: '2px' }}>
                        {product.type !== 'prep-other' ? 'Price: $' : 'Cost: $'}
                        {Number(product.price).toLocaleString()}
                      </Typography>{' '}
                      <Typography
                        variant="span"
                        sx={{
                          gridColumnStart: '4',
                          textAlign: 'right',
                          display: 'grid',
                          gridTemplateRows: '1fr 1fr',
                        }}
                      >
                        {formatDate(product.date)}{' '}
                        {product.sold && (
                          <Typography>
                            <span
                              style={{
                                color: 'lightgreen',
                                fontWeight: 'bold',
                                marginRight: '10px',
                                textAlign: 'right',
                                gridRowStart: '2',
                              }}
                            >
                              {' '}
                              SOLD
                            </span>
                          </Typography>
                        )}
                      </Typography>{' '}
                      <EditOutlinedIcon
                        onClick={() => {
                          handleEditClick(product);
                          setSelectedProduct(product);
                        }}
                        disabled={restricted ? restricted : false}
                        sx={{ cursor: 'pointer', fontSize: 'large' }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'grid',
                        justifyContent: 'space-between',
                        // alignItems: 'center',
                        padding: '0px',
                      }}
                    >
                      <Box
                        sx={{
                          gridColumnStart: '2',
                          gridColumnEnd: '3',
                          display: 'flex',
                          gap: '8px',
                        }}
                      >
                        {' '}
                        <DeleteForeverOutlinedIcon
                          sx={{
                            fontSize: 'large',
                            color: 'error.main',
                            cursor: 'pointer',
                            margin: '0 4px 0 0',
                            position: 'relative',
                            top: '-4px',
                          }}
                          onClick={() => handleDeleteClick(product)}
                        />
                      </Box>
                    </Grid>
                  </>
                ) : (
                  // Desktop View Layout =================================================
                  // Desktop View Layout =================================================
                  // Desktop View Layout =================================================
                  <>
                    <Grid container alignItems="center" sx={{ height: '100%' }}>
                      <Grid
                        item
                        container
                        alignItems="center"
                        spacing={0}
                        key={product.id}
                        sx={{
                          height: '50px',
                          marginBottom: '0',
                          padding: '0',
                          position: 'relative',
                          top: '-10px',
                          display: 'grid',
                          gridTemplateColumns: '1fr 1fr 2fr 2fr 3fr 2fr 3fr 1fr ',
                          gridTemplateRows: '50px',
                          gap: '4px',
                        }}
                      >
                        {/* Image Column */}
                        <Grid sx={{ display: 'grid ' }}>
                          <Box
                            component="img"
                            sx={{
                              height: 54,
                              width: 54,
                              borderRadius: '5px',
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '0px',
                              left: '-2px',
                              marginTop: '6px',
                            }}
                            src={product.image_url}
                            alt={product.title}
                            onClick={() => handleImageClick(product.image_url)}
                          />
                        </Grid>
                        {/* Date and Sold Status */}
                        <Grid
                          item
                          xs={1}
                          sx={{
                            transform: 'translateY(8px)',
                            alignSelf: 'center',
                          }}
                        >
                          {product.sold && (
                            <Typography>
                              <span style={{ color: 'lightgreen', fontWeight: 'bold' }}> SOLD</span>
                            </Typography>
                          )}
                        </Grid>
                        {/* Product Type */}
                        <Grid
                          item
                          sx={{
                            transform: 'translateY(6px)',
                            height: '100%',
                          }}
                        >
                          <Typography
                            sx={{
                              backgroundColor: productTypeColors[product.type] || '#FFFFFF',
                              borderRadius: '5px',
                              width: '90%',
                              textTransform: 'capitalize',
                              display: 'inline-block',
                              height: '100%',
                              paddingTop: '14px',
                            }}
                          >
                            {product.type}
                          </Typography>
                        </Grid>
                        {/* Product Title */}
                        <Grid item height="100%">
                          <Typography textAlign={'left'} sx={{ transform: 'translateY(10px)' }}>
                            {product.title}
                          </Typography>
                        </Grid>
                        {/* Product Category */}
                        <Grid item>
                          <Typography
                            color="text.secondary"
                            sx={{
                              transform: 'translateY(-2px) translateX(30px)',
                              textAlign: 'left',
                            }}
                            variant="body2"
                          >
                            {product.category}
                          </Typography>
                        </Grid>
                        {/* Product Price */}
                        <Grid item sx={{ transform: 'translateY(-2px)' }}>
                          <Typography
                            sx={{
                              borderRadius: '5px',
                              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                            variant="body2"
                            className={
                              product.type === 'auction' && parseFloat(product.price) === 0
                                ? 'highlight-price'
                                : ''
                            }
                          >
                            {product.type !== 'prep-other' ? 'Price: $' : 'Cost: $'}
                            <span
                              style={{ color: product.type === 'prep-other' ? 'red' : 'white' }}
                            >
                              {Number(product.price).toLocaleString()}
                            </span>
                          </Typography>
                        </Grid>
                        {/* Conditional Day Total Display */}
                        {isLastProductOfDate(index) && (
                          <Grid item sx={{ position: 'relative', top: '8px', left: '30%' }}>
                            {getDayTotalDisplay(product, index)}
                          </Grid>
                        )}{' '}
                        {/* Edit and Delete Icons */}
                        <Grid
                          item
                          sx={{
                            display: 'grid',
                            gap: '6px',
                            // alignItems: 'center',
                            position: 'relative',
                            top: '10px',
                            gridColumn: '8',
                            justifySelf: 'end',
                          }}
                        >
                          {/* <Grid item> */}
                          <EditOutlinedIcon
                            onClick={() => {
                              handleEditClick(product);
                              setSelectedProduct(product);
                            }}
                            disabled={restricted}
                            sx={{ cursor: 'pointer', fontSize: 'large' }}
                          />
                          <DeleteForeverOutlinedIcon
                            onClick={() => handleDeleteClick(product)}
                            sx={{ fontSize: 'extraLarge', color: 'error.main', cursor: 'pointer' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: isMobile ? '60%' : '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '100%' : '45%',
            height: '100%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton onClick={handleCloseModal} sx={{ position: 'absolute', top: 8, right: 8 }}>
            <CloseIcon />
          </IconButton>
          <Box component="img" src={modalImage} alt="Product Image" sx={{ width: '100%' }} />
        </Box>
      </Modal>
    </>
  );
};

export default ProductGrid;
