import React, { useEffect } from 'react';

export default function AnimatedEllipsis() {
  const [dots, setDots] = React.useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 6 ? prevDots + '.' : ''));
    }, 400);

    return () => clearInterval(interval);
  }, []);

  return <span>{dots}</span>;
}
