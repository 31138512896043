import { useParams } from 'react-router-dom';
import {
  deleteImage,
  deleteImageData,
  getPostDetail,
  postAddImages,
  updatePost,
} from '../../services/fetch-utils.js';
import PostForm from '../PostForm/PostForm.js';
import { usePost } from '../../hooks/usePost.js';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../context/QueryContext.js';
import { editProducts } from '../../services/fetch-products.js';
import { useProducts } from '../../hooks/useProducts.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function EditPost() {
  const { id } = useParams();

  const { products, setProducts } = useProducts();

  const navigate = useNavigate();
  const {
    postDetail,
    setLoading,
    error,
    setError,
    imageUrls,
    setImageUrls,
    additionalImageUrlsPublicIds,
    additionalImages,
  } = usePost(id);

  const [deletedImagePublicIds, setDeletedImagePublicIds] = useState([]);

  const { setNewPostCreated } = useQuery(); // We can use the same context state for edits since the desired effect is the same: refresh the post list.

  if (error) return <h1>{error}</h1>;
  const getThumbnailUrl = (mediaUrl) => {
    if (mediaUrl.endsWith('.mp4')) {
      return mediaUrl.replace('.mp4', '.jpg');
    }

    return mediaUrl;
  };

  const handleEditProduct = async (productData) => {
    try {
      const response = await editProducts(productData);

      if (response) {
        setProducts((prevProducts) => {
          // Map through the existing products to update the edited product
          const updatedProducts = prevProducts.map((p) => {
            if (p.id === productData.id) {
              // Replace with the updated product data
              return response;
            }
            return p;
          });

          // Sort the array by the 'date' property
          updatedProducts.sort((a, b) => a.date - b.date);

          return updatedProducts;
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to edit post:', error);
      toast.error(`Failed to edit post: ${error}`, {
        theme: 'dark',
        draggable: true,
        draggablePercent: 60,
      });
    }
  };

  //  handleSubmit is called when the user clicks the submit button on the EditPost form
  const handleSubmit = async (post, currentImages, deletedImages) => {
    setLoading(true);

    if (currentImages.length === 0) {
      post.image_url = post.newImages[0].secure_url;
    } else {
      // sets the thumbnail image to first image in currentImages array
      post.image_url = currentImages[0];
    }

    // get the number of images for the post and public_id for thumbnail
    const { num_imgs, public_id } = await getPostDetail(id);

    post.num_imgs = num_imgs;
    post.public_id = public_id;

    try {
      // Edit post in db
      post = { ...post, customer_id: post.customerId };
      // console.log('post', post); s

      await updatePost(postDetail.id, post);
      await postAddImages(post.newImages, postDetail.id);

      // Find the product that corresponds to this post

      let matchedProduct = products.find((product) => product.post_id === id);

      // console.log('matchedProduct', matchedProduct);

      if (matchedProduct) {
        // If a matching product is found, handle editing the product
        matchedProduct = {
          ...matchedProduct,
          category: post.category,
          price: post.price,
          title: post.title,
          description: post.description,
          image_url: post.image_url,
          public_id: post.public_id,
          sold: post.sold,
        };
        await handleEditProduct(matchedProduct);
      }

      // Delete removed images from the database and Cloudinary
      for (const removedImageUrl of deletedImages) {
        const removedImage = additionalImages.find((image) => image.image_url === removedImageUrl);

        if (removedImage) {
          await deleteImage(removedImage.public_id, removedImage.resource_type);
          await deleteImageData(id, removedImage.public_id);
        }
      }
      setNewPostCreated((prevState) => !prevState); // Toggle newPostCreated state after post edit

      navigate('/dashboard');
    } catch (e) {
      setError(e.message);
      // eslint-disable-next-line no-console
      console.error('No matching product found for this post.');
      toast.error(`No matching product found for this post: ${error}`, {
        theme: 'dark',
        draggable: true,
        draggablePercent: 60,
      });
    }
  };

  return (
    <PostForm
      {...postDetail}
      submitHandler={handleSubmit}
      setImageUrls={setImageUrls}
      imageUrls={imageUrls}
      additionalImageUrlsPublicIds={additionalImageUrlsPublicIds}
      additionalImages={additionalImages}
      deletedImagePublicIds={deletedImagePublicIds}
      setDeletedImagePublicIds={setDeletedImagePublicIds}
      getThumbnailUrl={getThumbnailUrl}
      products={products}
    />
  );
}
