import { Box, Container, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useLoadingState from '../../../context/LoadingContext.js';
import { deleteOnCancel } from '../../../services/stripe.js';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Cancel() {
  const navigate = useNavigate();

  const { setPageLoading } = useLoadingState();

  // Deletion logic
  useEffect(() => {
    async function performDeletion() {
      try {
        await deleteOnCancel();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Deletion failed:', error.message);
        toast.error(`Error cancelling order: ${error.message}`, {
          theme: 'dark',
          draggable: true,
          draggablePercent: 60,
          toastId: 'orders-2',
          autoClose: false,
        });
      }
    }
    performDeletion();
  }, []); // Empty dependency since this should only run once

  // Navigation logic
  useEffect(() => {
    let timeout = setTimeout(() => {
      setPageLoading(true);
      navigate('/subscription');
      let nestedTimeout = setTimeout(() => {
        setPageLoading(false);
      }, 500);
      return () => {
        clearTimeout(timeout);
        clearTimeout(nestedTimeout);
      };
    }, 5000);
  }, [navigate, setPageLoading]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Container maxWidth="xs">
        <Typography variant="h1" color="error">
          Canceled Operation
        </Typography>
        <Typography variant="h6">
          please try again, or contact support if the problem persists.
        </Typography>
      </Container>
    </Box>
  );
}
