import React, { createContext, useContext, useEffect, useState } from 'react';
import useStripeCustomer from '../hooks/useStripeCustomer.js';
import { useAuthContext } from '../context/AuthContext'; // Import the Auth context to monitor login state
import { ToastContainer, toast } from 'react-toastify';

const StripeContext = createContext();

export const StripeProvider = ({ children }) => {
  // const [customerId, setCustomerId] = useState(null);
  const { customerId, setCustomerId } = useAuthContext();
  const [subscription, setSubscription] = useState(null);
  const [stripeEmail, setStripeEmail] = useState('');
  const [stripeName, setStripeName] = useState('');
  const [loadingCustomerId, setLoadingCustomerId] = useState(false);

  const { checkStripeCustomer } = useStripeCustomer();
  const { isAuthenticated, user } = useAuthContext(); // Get authentication state from AuthContext

  useEffect(() => {
    const fetchStripeCustomerDetails = async () => {
      if (!isAuthenticated || !user) {
        return;
      }

      // Only fetch if user is authenticated
      setLoadingCustomerId(true);
      try {
        const stripeCustomer = await checkStripeCustomer();

        setStripeName(stripeCustomer.data.name);
        setStripeEmail(stripeCustomer.data.email);

        if (stripeCustomer.data.subscription) {
          setSubscription(stripeCustomer.data.subscription.isActive);
        }

        if (stripeCustomer.data && stripeCustomer.data.customerId) {
          setCustomerId(stripeCustomer.data.customerId);
        } else {
          setCustomerId(null);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching Stripe customer details:', error);
        setCustomerId(null);
        toast.error(`Error checking for customer details: ${error}`, {
          theme: 'dark',
          draggable: true,
          draggablePercent: 60,
          toastId: 'stripeContext-1',
          autoClose: false,
        });
      } finally {
        setLoadingCustomerId(false);
      }
    };

    fetchStripeCustomerDetails();
  }, [isAuthenticated, user, setCustomerId]);

  return (
    <StripeContext.Provider
      value={{
        customerId,
        setCustomerId,
        subscription,
        setSubscription,
        stripeEmail,
        stripeName,
        loadingCustomerId,
      }}
    >
      {children}
    </StripeContext.Provider>
  );
};

export const useStripeContext = () => useContext(StripeContext);
