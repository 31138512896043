import { Link } from 'react-router-dom';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { usePost } from '../../hooks/usePost.js';
import {
  deleteById,
  deleteImage,
  getAdditionalImageUrlsPublicIds,
} from '../../services/fetch-utils.js';
import './PostCard.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../context/QueryContext.js';
import { deleteProduct } from '../../services/fetch-products.js';
import { usePosts } from '../../hooks/usePosts.js';
import { useAuthContext } from '../../context/AuthContext.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function PostCard({
  id,
  posts,
  title,
  description,
  image_url,
  category,
  price,
  setPosts,
  products,
  setProducts,
  restricted,
}) {
  const { isAuthenticated } = useAuthContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const { setNewPostCreated } = useQuery();

  const { setLoading, setError, postDetail } = usePost(id);

  const [deletedRowId, setDeletedRowId] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);

  // const { restricted } = usePosts();

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = async () => {
    handleCloseDialog();
    handleDelete();
  };

  // const [isCompleted, setIsCompleted] = useState(completed);   // use later and change this to isSold/ isAvailable or something like that

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/auth/sign-in');
    }
  }, [isAuthenticated, navigate]);

  // delete the post and corresponding quota-tracking post from the database
  const handleDelete = async () => {
    navigate(`/dashboard/`);
    try {
      setDeletedRowId(id);
      // grab urls out of my database
      const postUrls = await getAdditionalImageUrlsPublicIds(id);

      // delete all images from cloudinary
      for (let i = 0; i < postUrls.length; i++) {
        await deleteImage(postUrls[i].public_id, postUrls[i].resource_type);
      }

      // delete corresponding quota-tracking post from database
      const postId = id; // The ID of the post to delete

      const matchingProduct = products.find((product) => product.post_id === postId);

      if (matchingProduct) {
        await deleteProduct(matchingProduct.id);

        // Update the products state to exclude the deleted product
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.id !== matchingProduct.id)
        );
      }

      // delete the post from my database
      await deleteById(id);

      // delete the post from state, so it doesn't show up on the page  /______________________________ this is from my old code which works- it is from a hook instead of context...
      const updatedPosts = posts.filter((post) => post.id !== id);
      setPosts(updatedPosts);
      setLoading(true);

      // After successfully deleting the post from the database and updating local state:
      setNewPostCreated((prevState) => !prevState); // Toggle newPostCreated state after post deletion
    } catch (e) {
      setError(e.message);
      // eslint-disable-next-line no-console
      console.error('Error in handleDelete:', e.message);
      toast.error(`Error deleting post: ${e}`, {
        theme: 'dark',
        draggable: true,
        draggablePercent: 60,
        toastId: 'orders-2',
        autoClose: false,
      });
    }
  };

  const handleEditPost = () => {
    navigate(`/dashboard/edit/${id}`);
  };

  return (
    <div className={`post ${postDetail.id === deletedRowId ? 'grayed-out' : ''}`} key={id}>
      <Link to={`/gallery/${id}`}>
        {image_url && (
          <img
            className="admin-prod-img"
            src={image_url.endsWith('.mp4') ? `${image_url.slice(0, -4)}.jpg` : image_url}
            alt="edit"
          />
        )}
      </Link>

      <span className="grid-s2 grid-e3 mobile-title">
        {title.length > 25 ? title.slice(0, 25) + '...' : title}
      </span>
      <Box className="title-price-container">
        <Typography variant="body1" className="mobile-title-desk">
          {title}
        </Typography>
        <Grid container>
          <Typography variant="body1" sx={{ textAlign: 'right' }}>
            ${Number(price).toLocaleString()}
            <span
              style={{
                color: 'lightgreen',
                fontWeight: 'bold',
                display: isMobile ? 'block' : 'inline',
              }}
            >
              {postDetail.sold === true ? ' SOLD' : ''}
            </span>
          </Typography>
        </Grid>
      </Box>
      <Typography variant="body1" className="cat-desk">
        {category}
      </Typography>
      <Typography variant="body1" className="desc-desk">
        {description}
      </Typography>

      <div className="admin-prod-btn-cont grid-7">
        {
          <Button
            onClick={handleEditPost}
            sx={{ fontSize: '.7rem' }}
            disabled={restricted ? restricted : false}
          >
            Edit
          </Button>
        }
        <Button onClick={handleOpenDialog} sx={{ fontSize: '.7rem' }}>
          Delete
        </Button>
      </div>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting this post will remove it permanently. This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
