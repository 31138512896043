import { useEffect, useState } from 'react';
import { fetchOrders } from '../services/fetch-orders.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function useOrders(customerId) {
  const [orders, setOrders] = useState([]);
  const [orderError, setOrderError] = useState('');
  const [orderLoading, setOrderLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setOrderLoading(true);
        const orders = await fetchOrders();
        // Check if the server responded with a non-200 status
        if (orders) {
          setOrders(orders);
          setOrderLoading(false);
        } else {
          // eslint-disable-next-line no-console
          console.error('Error fetching orders');
          throw new Error('Error fetching orders');
        }
      } catch (e) {
        // setOrderError('Error fetching orders', e.message); // Set error state
        setOrderError(`Error fetching orders: ${e.message}`);
        toast.error(`Error fetching orders: ${e}`, {
          theme: 'dark',
          draggable: true,
          draggablePercent: 60,
          toastId: 'useOrders-1',
          autoClose: false,
        });
        return null;
      }
    };

    fetchData();
  }, [customerId]);

  return {
    orders,
    setOrders,
    orderLoading,
    setOrderLoading,
    orderError,
    setOrderError,
  };
}
