import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { usePosts } from '../../hooks/usePosts.js';
import CloseIcon from '@mui/icons-material/Close';

import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  ButtonGroup,
  useMediaQuery,
  useTheme,
  Checkbox,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
export default function InventoryMgtForm({
  selectedButton,
  handleNewClick,
  mode,
  handleProductSubmit,
  handleProductChange,
  product,
  products,
  dateAsNumber,
  setProduct,
  files,
  setFiles,
  currentImage,
  setCurrentImage,
  handleFormClose,
  createGalleryPost,
  setCreateGalleryPost,
  formLoading,
}) {
  // State to manage the current image
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { restricted } = usePosts();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (mode === 'edit' && product.image_url) {
      setCurrentImage(product.image_url); // Single image for product thumbnail
      setFiles([]); // Clear any previous gallery images
    }
  }, [product.image_url, mode, setFiles, setCurrentImage]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const mappedFiles = acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );

      if (createGalleryPost) {
        // Handle multiple images for gallery post
        setFiles((prevFiles) => [...prevFiles, ...mappedFiles]); // Append new files to existing ones
        setCurrentImage(mappedFiles); // Display multiple thumbnail images
      } else {
        // Handle single image for product thumbnail
        setFiles(mappedFiles); // Set the single image in files
        setCurrentImage(mappedFiles[0].preview); // Display the first image as thumbnail
      }
    },
    [createGalleryPost, setFiles, setCurrentImage]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    multiple: createGalleryPost ? true : false, // Enable multiple file uploads
    maxFiles: 10,
  });

  useEffect(() => {
    let newCategories = [
      'Beads',
      'Blunt tips',
      'Bubblers',
      'Collabs',
      'Cups',
      'Dry Pieces',
      'Goblets',
      'Iso stations',
      'Marbles',
      'Pendants',
      'Recyclers',
      'Rigs',
      'Slides',
      'Spinner caps',
      'Terp pearls',
      'Tubes',
      'Misc',
    ];

    setCategories(newCategories);
  }, [products]);

  const handleImageDelete = (index) => {
    if (createGalleryPost) {
      setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    } else {
      setCurrentImage(''); // Clear the current image (product thumbnail)
    }
  };

  // Display thumbnails of all uploaded images
  const renderThumbnails = () => (
    <div className="thumbnails-container">
      {createGalleryPost ? (
        files.map((file, index) => (
          <div key={file.name} className="thumbnail-wrapper">
            <img src={file.preview} alt={`Preview ${index + 1}`} className="thumbnail" />
            <button
              type="button"
              className="delete-button-form"
              onClick={() => handleImageDelete(index)}
            >
              X
            </button>
          </div>
        ))
      ) : (
        <div className="thumbnail-wrapper">
          {currentImage && (
            <>
              <img src={currentImage} alt="Current image" className="thumbnail" />
              <button
                type="button"
                className="delete-button-form"
                onClick={() => setCurrentImage('')} // Clear the thumbnail for product
              >
                X
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );

  const handleCheckboxChange = (event) => {
    setCreateGalleryPost(event.target.checked);
    setFiles([]);
  };

  return (
    <Box
      elevation={2}
      className="form-container-2"
      sx={{
        padding: 1,
        borderRadius: '5px',
        backgroundColor: 'background.paper',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        <ButtonGroup>
          <Button
            variant={selectedButton === 'new' ? 'contained' : 'outlined'}
            onClick={handleNewClick}
            sx={{ marginRight: '10px', display: selectedButton === 'new' ? 'none' : 'block' }}
          >
            New
          </Button>
        </ButtonGroup>
      </Box>
      {mode === 'new' ? (
        <>
          {isMobile && (
            <Button
              onClick={handleFormClose}
              sx={{
                position: 'absolute',
                right: 5,
                top: 5,
                minWidth: 'auto',
                padding: 0,
              }}
            >
              <CloseIcon />
            </Button>
          )}
          <form onSubmit={handleProductSubmit} className="quota-tracking-form">
            <Typography variant="body1" sx={{ margin: '10px', textAlign: 'left' }}>
              Enter new product:
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridTemplateRows: '45px',
                marginBottom: 1,
              }}
            >
              {' '}
              <FormControl variant="outlined">
                <InputLabel id="product-type-label" sx={{ fontSize: '0.75rem' }}>
                  Type
                </InputLabel>
                <Select
                  labelId="product-type-label"
                  name="type"
                  value={product.type}
                  onChange={handleProductChange}
                  label="Type"
                  sx={{
                    height: '45px',
                    '& .MuiInputBase-root': {
                      height: 45, //
                      // fontSize: '0.875rem',
                      // padding: '6px 12px',
                    },
                    '& .MuiSelect-select:focus': {
                      backgroundColor: 'transparent',
                      height: 30,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      // top: 0,
                    },
                  }}
                  required
                >
                  <MenuItem value="auction">Auction</MenuItem>
                  <MenuItem value="direct-sale">Direct Sale</MenuItem>
                  <MenuItem value="inventory">Website</MenuItem>
                  <MenuItem value="prep-other">Prep/ Other</MenuItem>
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  // fullWidth
                  value={product.date ? new Date(product.date) : null}
                  onChange={(newValue) => {
                    // Convert the Date object back to a timestamp and update the product state
                    const newDateValue = newValue ? newValue.getTime() : null;
                    setProduct((prev) => ({ ...prev, date: newDateValue }));
                  }}
                  required
                  sx={{
                    marginBottom: 1, //
                    '& .MuiInputBase-root': {
                      height: 45,
                      fontSize: '0.875rem',
                      padding: '0 10px 0 0',
                    },
                    '& .MuiInputLabel-root': {
                      // transform: 'translate(14px, 8px) scale(1)',
                      fontSize: '.8rem',
                    },
                  }}
                  // renderInput={(params) => <TextField {...params} fullWidth />}
                  slotProps={{
                    textField: {
                      // Pass all the TextField props here
                      fullWidth: true,

                      // Add other props you need for the TextField
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
            <TextField
              fullWidth
              label="Number of Days"
              required
              name="num_days"
              type="number"
              value={product.num_days || ''}
              onChange={handleProductChange}
              inputProps={{ min: '1', step: '1' }}
              sx={{
                marginBottom: 1, //
                '& .MuiInputBase-root': {
                  height: 45,
                  fontSize: '0.875rem',
                  padding: '0 0 0 75%',
                },
                '& .MuiInputLabel-root': {
                  // transform: 'translate(14px, 8px) scale(1)',
                  fontSize: '0.8rem',
                },
              }}
            />
            {product.type !== 'prep-other' && (
              <>
                {' '}
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  value={product.title || ''}
                  onChange={handleProductChange}
                  sx={{
                    marginBottom: 1, //
                    '& .MuiInputBase-root': {
                      height: 45,
                      fontSize: '0.875rem',
                      padding: '0 30%',
                    },
                    '& .MuiInputLabel-root': {
                      // transform: 'translate(14px, 8px) scale(1)',
                      fontSize: '0.8rem',
                    },
                  }}
                  required
                />
                <FormControl fullWidth variant="outlined" sx={{ marginBottom: 1 }}>
                  <InputLabel id="category" sx={{ fontSize: '0.75rem', top: '-5px' }}>
                    Category
                  </InputLabel>
                  <Select
                    labelId="category"
                    name="category"
                    value={product.category || ''}
                    onChange={handleProductChange}
                    label="Type"
                    sx={
                      {
                        // '& .MuiInputBase-root': {
                        //   height: 45,
                        //   fontSize: '0.875rem',
                        //   padding: '6px 40%',
                        // },
                      }
                    }
                    required
                  >
                    {categories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            <TextField
              fullWidth
              label={product.type !== 'prep-other' ? 'Price' : 'Material Costs'}
              required
              name="price"
              type="number"
              value={product.price || ''}
              onChange={handleProductChange}
              inputProps={{ step: 1 }}
              sx={{
                marginBottom: 1,
                '& .MuiInputBase-root': {
                  height: 45,
                  fontSize: '0.875rem',
                  padding: '0 0 0 75%',
                },
                '& .MuiInputLabel-root': {
                  //   transform: 'translate(14px, 8px) scale(1)',
                  fontSize: '0.8rem',
                },
              }}
            />
            <TextField
              fullWidth
              multiline
              // rows={4}
              label="Description"
              required
              name="description"
              value={product.description || ''}
              onChange={handleProductChange}
              sx={{
                // marginBottom: 1,
                // '& .MuiInputBase-root': {
                //   height: 85,
                //   fontSize: '0.875rem',
                //   padding: '0 20%',
                //   overflowY: 'scroll',
                // },
                '& .MuiInputLabel-root': {
                  //   transform: 'translate(10px, 0px) scale(1)',
                  fontSize: '0.75rem',
                },
              }}
            />
            <FormControl
              component="fieldset"
              className="sold-radio-group"
              sx={{ marginBottom: '10px', width: '100%' }}
            >
              <FormLabel component="legend">Sold Status</FormLabel>
              <RadioGroup
                className=".sold-radio-group"
                aria-label="sold status"
                name="sold" // Set name to 'sold'
                value={product.sold ? 'true' : 'false'}
                onChange={handleProductChange}
                row
                sx={{
                  fontSize: '1rem',
                  '& .MuiTypography-root': {
                    fontSize: '1rem',
                  },
                }}
              >
                <FormControlLabel value="true" control={<Radio />} label="Sold" />
                <FormControlLabel value="false" control={<Radio />} label="Available" />
              </RadioGroup>
            </FormControl>
            <Box
              sx={{
                display: 'grid',
                gridTemplateRows: '1fr',
                gridTemplateColumns: '1fr 4fr',
                alignItems: 'center',
                justifyContent: 'start',
                border: '1px solid',
                borderColor: (theme) => theme.palette.primary.light,
                margin: '10px 0 10px 0',
              }}
            >
              <Checkbox
                checked={createGalleryPost}
                onChange={handleCheckboxChange}
                sx={{ gridColumn: '1' }}
              />
              <span> Create new gallery post</span>
            </Box>
            <Box
              {...getRootProps()}
              className="dropzone"
              sx={{
                borderColor: (theme) => theme.palette.primary.light,
                borderWidth: '1px',
                borderStyle: 'solid',
                borderRadius: '5px',
                padding: '10px',
                marginBottom: '10px',
                height: isMobile ? '45px' : '70px',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: (theme) => theme.palette.primary.main,
                },
              }}
            >
              <input {...getInputProps()} />
              {isMobile ? (
                <Typography> Tap to select an image </Typography>
              ) : (
                <Typography>
                  Drag &apos;n&apos; drop an image here, or click to select an image
                </Typography>
              )}
            </Box>
            {/* Render the thumbnail */}
            {currentImage && <aside className="thumbs-container">{renderThumbnails()}</aside>}

            {
              <Box paddingBottom="40px">
                <Button variant="outlined" onClick={handleNewClick} sx={{ marginRight: '10px' }}>
                  Reset
                </Button>
                <Button type="submit" variant="contained" disabled={formLoading || restricted}>
                  {formLoading ? 'Uploading' : restricted ? 'Tracking disabled' : 'Add Product'}
                </Button>
              </Box>
            }
          </form>
        </>
      ) : (
        <>
          {' '}
          {isMobile && (
            <Button
              onClick={handleFormClose}
              sx={{
                position: 'absolute',
                right: 5,
                top: 5,
                minWidth: 'auto',
                padding: 0,
              }}
            >
              <CloseIcon />
            </Button>
          )}
          <form onSubmit={handleProductSubmit} className="quota-tracking-form">
            {/* Edit mode  ================================================================== */}
            <Typography sx={{ margin: '10px' }}>Edit product:</Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: 1 }}>
              <InputLabel sx={{ fontSize: '0.75rem' }}>Type</InputLabel>
              <Select
                labelId="product-type-label"
                name="type"
                value={product.type || ''}
                onChange={handleProductChange}
                label="Type"
                required
                sx={{
                  '& .MuiInputBase-root': {
                    // height: 45, //
                    // fontSize: '0.875rem',
                    // padding: '6px 12px',
                  },
                  '& .MuiSelect-select:focus': {
                    backgroundColor: 'transparent',
                    height: 30,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    // top: 0,
                  },
                }}
              >
                <MenuItem value="auction">Auction</MenuItem>
                <MenuItem value="direct-sale">Direct Sale</MenuItem>
                <MenuItem value="inventory">Website</MenuItem>
                <MenuItem value="prep-other">Prep/ Other</MenuItem>
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date"
                value={dateAsNumber ? dateAsNumber : null}
                onChange={(newValue) => {
                  setProduct((prev) => ({ ...prev, date: newValue.getTime() }));
                }}
                required
                sx={{
                  marginBottom: 1, //
                  '& .MuiInputBase-root': {
                    height: 45,
                    fontSize: '0.875rem',
                    padding: '0 20%',
                  },
                  '& .MuiInputLabel-root': {
                    // transform: 'translate(14px, 8px) scale(1)',
                    // fontSize: '0.75rem',
                  },
                }}
                slotProps={{
                  textField: {
                    // Pass all the TextField props here
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
            <TextField
              fullWidth
              label="Number of Days"
              name="num_days"
              value={product ? product.num_days : ''}
              onChange={handleProductChange}
              required
              inputProps={{ min: '1', step: '1' }}
              sx={{
                marginBottom: 1, //
                '& .MuiInputBase-root': {
                  height: 45,
                  fontSize: '0.875rem',
                  padding: '6px 40%',
                },
                '& .MuiInputLabel-root': {
                  // transform: 'translate(14px, 8px) scale(1)',
                  // fontSize: '0.75rem',
                },
              }}
            />
            {product.type !== 'prep-other' && (
              <>
                {' '}
                <TextField
                  fullWidth
                  label="Title "
                  name="title"
                  value={product ? product.title : ''}
                  required
                  onChange={handleProductChange}
                  sx={{
                    marginBottom: 1,
                    '& .MuiInputBase-root': {
                      height: 45,
                      fontSize: '0.875rem',
                      padding: '0 10%',
                    },
                    '& .MuiInputLabel-root': {
                      transform: 'translate(14px, 8px) scale(1)',
                      fontSize: '0.75rem',
                    },
                  }}
                />
                <FormControl fullWidth variant="outlined" sx={{ marginBottom: 1 }}>
                  <InputLabel id="category">Category</InputLabel>
                  <Select
                    labelId="category"
                    name="category"
                    value={product.category || ''}
                    onChange={handleProductChange}
                    required
                    label="Type"
                  >
                    {categories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            <TextField
              fullWidth
              label={product.type !== 'prep-other' ? 'Price' : 'Material Costs'}
              name="price"
              type="number"
              value={product ? product.price : ''}
              onChange={handleProductChange}
              required
              inputProps={{ step: 1 }}
              sx={{
                marginBottom: 1,
                '& .MuiInputBase-root': {
                  height: 45,
                  fontSize: '0.875rem',
                  padding: '6px 40%',
                },
                '& .MuiInputLabel-root': {
                  // transform: 'translate(14px, 8px) scale(1)',
                  // fontSize: '0.75rem',
                },
              }}
            />
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={product ? product.description : ''}
              onChange={handleProductChange}
              required
              sx={
                {
                  // marginBottom: 1, // Reduce the margin bottom
                  // '& .MuiInputBase-root': {
                  //   height: 85, // Set the desired height
                  //   fontSize: '0.875rem', // Adjust font size
                  //   padding: '0 20%', // Adjust padding for better alignment
                  //   overflowY: 'scroll',
                  // },
                  // '& .MuiInputLabel-root': {
                  //   transform: 'translate(10px, 0px) scale(1)', // Adjust label position
                  //   fontSize: '0.75rem', // Reduce label font size
                  // },
                }
              }
            />

            <FormControl
              component="fieldset"
              className="sold-radio-group-2"
              sx={{
                width: '100%',
              }}
            >
              <FormLabel component="legend" sx={{ fontSize: '.8rem' }}>
                Sold Status
              </FormLabel>
              <RadioGroup
                className=".sold-radio-group-2"
                aria-label="sold status"
                name="sold" // Set name to 'sold'
                value={product.sold ? 'true' : 'false'}
                onChange={handleProductChange}
                row
                sx={{
                  fontSize: '1rem',
                  '& .MuiTypography-root': {
                    fontSize: '1rem',
                  },
                }}
              >
                <FormControlLabel value="true" control={<Radio />} label="Sold" />
                <FormControlLabel value="false" control={<Radio />} label="Available" />
              </RadioGroup>
            </FormControl>
            {!product.post_id ? (
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateRows: '1fr',
                  gridTemplateColumns: '1fr 4fr',
                  alignItems: 'center',
                  justifyContent: 'start',
                  border: '1px solid',
                  borderColor: (theme) => theme.palette.primary.light,
                  margin: '10px 0 10px 0',
                }}
              >
                <Checkbox
                  checked={createGalleryPost}
                  onChange={handleCheckboxChange}
                  sx={{ gridColumn: '1' }}
                />
                <span> Create new gallery post</span>
              </Box>
            ) : (
              <span></span>
            )}
            <Box
              {...getRootProps()}
              className="dropzone"
              sx={{
                borderColor: (theme) => theme.palette.primary.light,
                borderWidth: '1px',
                borderStyle: 'solid',
                borderRadius: '5px',
                padding: '10px',

                marginBottom: '10px',
                height: isMobile ? '45px' : '70px',

                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: (theme) => theme.palette.primary.main,
                },
              }}
            >
              <input {...getInputProps()} />
              {isMobile ? (
                <Typography> Tap to select an image </Typography>
              ) : (
                <Typography>
                  Drag &apos;n&apos; drop an image here, or click to select an image
                </Typography>
              )}
            </Box>
            {currentImage && (
              <aside className="thumbs-container">
                <div className="thumbnail-wrapper">
                  <img src={currentImage} alt="Current image" className="thumbnail" />
                  <button className="delete-button-form" onClick={handleImageDelete}>
                    X
                  </button>
                </div>
              </aside>
            )}
            {
              <Button type="submit" variant="contained" disabled={formLoading || restricted}>
                {formLoading ? 'Uploading' : restricted ? 'Tracking disabled' : 'Save Changes'}
              </Button>
            }
          </form>
        </>
      )}
    </Box>
  );
}
