/* eslint-disable no-console */
import { AmazonCognitoIdentity, userPool } from '../services/userPool.js';
import { createCookies, deleteCookies } from '../services/cookieAPI.js';
import { ToastContainer, toast } from 'react-toastify';

export default function useAuth({ setUser, setError, setCookiesSet }) {
  const fetchAuth = async ({ email, password, type }) => {
    if (type === 'sign-up') {
      try {
        const result = await new Promise((resolve, reject) => {
          const attributeList = [
            new AmazonCognitoIdentity.CognitoUserAttribute({
              Name: 'email',
              Value: email,
            }),
          ];

          userPool.signUp(email, password, attributeList, null, (e, result) => {
            if (e) {
              reject(e);
            } else {
              resolve(result);
            }
          });
        });
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        // fetch call to our server to enter email and sub into our database
        const resp = await fetch(`${BASE_URL}/api/v1/auth/new-user`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, sub: result.userSub }),
        });
        const data = await resp.json();
        if (resp.ok) {
          localStorage.setItem('needsVerification', 'true');
          window.location.replace('/auth/sign-in');

          return data;
        } else {
          throw data;
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    } else if (type === 'sign-in') {
      try {
        return await new Promise((resolve, reject) => {
          const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
            Username: email,
            Password: password,
          });

          const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
            Username: email,
            Pool: userPool,
          });

          cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: async (result) => {
              try {
                setUser(cognitoUser.username);
                await createCookies(result);
                resolve(result);
              } catch (e) {
                reject(new Error('[SYSTEM] Failed during onSuccess: ' + e.message));
              }
            },
            onFailure: (e) => {
              setError(e.message);
              if (e.code === 'UserNotFoundException' || e.code === 'NotAuthorizedException') {
                reject(new Error('Incorrect email or password'));
              } else if (e.code === 'UserNotConfirmedException') {
                reject(new Error('Email not confirmed'));
              } else {
                reject(new Error('[SYSTEM] ' + e.message));
              }
            },
          });
        });
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  };

  const handleForgotPassword = ({
    code,
    stage,
    setStage,
    email,
    password,
    setPassword,
    cPassword,
    setCPassword,
  }) => {
    const getUser = () => {
      return new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool,
      });
    };

    const sendVerificationCode = () => {
      getUser().forgotPassword({
        onSuccess: (result) => {
          console.info('on success', result);
        },
        onFailure: (e) => {
          console.error(e);
        },
        inputVerificationCode: (data) => {
          console.info(data);
          setStage(1);
        },
      });
    };

    const resetPassword = () => {
      if (password !== cPassword) {
        setError('Passwords do not match');
        console.error('Passwords do not match');
        return;
      }
      getUser().confirmPassword(code, password, {
        onSuccess: (data) => {
          console.info('onSuccess:', data);
          setStage(2);
          setPassword('');
          setCPassword('');
        },
        onFailure: (e) => {
          setError(e.message);
          console.error('onFailure:', e.message);
        },
      });
    };

    stage === 0 && sendVerificationCode();
    stage === 1 && resetPassword();
  };

  // const handleSignOut = async (signOutEmail, setUser, setIsAuthenticated) => {
  const handleSignOut = async (signOutEmail, setUser, setIsAuthenticated, setCustomerId) => {
    try {
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: signOutEmail,
        Pool: userPool,
      });

      // Sign out the user
      cognitoUser.signOut();

      // Wrap getSession in a Promise
      await new Promise((resolve, reject) => {
        cognitoUser.getSession((err, session) => {
          if (err || !session.isValid()) {
            resolve(); // Session is not valid or an error occurred, resolve here
          } else {
            reject('User is still signed in');
          }
        });
      });

      // After ensuring the user is signed out, proceed to clear cookies, local storage, etc.
      await deleteCookies();

      setUser(null);
      setIsAuthenticated(false);
      setCustomerId(null);
      setCookiesSet(false);
      window.localStorage.clear();
    } catch (e) {
      console.error('Error signing out:', e);
      toast.error(`Error signing out and deleting cookies: ${e}`, {
        theme: 'dark',
        draggable: true,
        draggablePercent: 60,
        toastId: 'useAuth-3',
        autoClose: false,
      });
    }
  };

  return { fetchAuth, handleSignOut, handleForgotPassword };
}
