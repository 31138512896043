import React, { createContext, useContext, useEffect, useState } from 'react';
import { userPool } from '../services/userPool.js';
import { createCookies } from '../services/cookieAPI.js';
import useLoadingState from './LoadingContext';
import useAuth from '../hooks/useAuth';
import useStripeCustomer from '../hooks/useStripeCustomer.js';
import { ToastContainer, toast } from 'react-toastify';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [email, setEmail] = useState('');
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [tokenExpiryTime, setTokenExpiryTime] = useState(null);
  const [isTokenValid, setIsTokenValid] = useState(true);

  const [cookiesSet, setCookiesSet] = useState(false);
  const [error, setError] = useState(null);
  const { loginLoading, setLoginLoading } = useLoadingState();
  const [password, setPassword] = useState('');
  const [cPassword, setCPassword] = useState('');
  const [customerId, setCustomerId] = useState(null); // <-- Managing customerId here
  const { checkStripeCustomer } = useStripeCustomer(); // Assuming this checks the customer ID

  const { fetchAuth, handleSignOut, handleForgotPassword } = useAuth({
    setUser,
    setError,
    setCookiesSet,
  });

  // sign up OR sign out depending on 'type'
  const handleRegistration = async (e, type, password, cPassword) => {
    e.preventDefault();
    try {
      if (type === 'sign-up' && password !== cPassword) {
        setError({ message: 'Passwords do not match' });
        throw new Error('Passwords do not match');
      }

      const result = await fetchAuth({ email, password, type });

      if (result) {
        setIsAuthenticated(true);
        setPassword('');
        setCPassword('');
        setError(null);
      } else {
        setIsAuthenticated(false);
      }
    } catch (e) {
      setError({ message: e.message });
      setIsAuthenticated(false);
      // eslint-disable-next-line no-console
      console.error(e.message.replace('[SYSTEM]'), '');

      if (
        e.message.startsWith('Passwords') ||
        e.message.startsWith('Incorrect') ||
        e.message.startsWith('A') ||
        e.message.startsWith('User') ||
        e.message.startsWith('Email')
      ) {
        // Show a warning toast for user-related issues
        toast.warn(e.message, {
          theme: 'dark',
          draggable: true,
          toastId: 'user-error',
          autoClose: 5000,
        });
      } else if (e.message.startsWith('[SYSTEM]')) {
        // Show an error toast for system-level issues
        toast.error(`An unexpected error occurred: ${e.message.replace('[SYSTEM] ', '')}`, {
          theme: 'dark',
          draggable: true,
          toastId: 'system-error',
          autoClose: false,
        });
      } else {
        // Handle any other unknown errors

        toast.error(`An unknown error occurred: ${e.message}`, {
          theme: 'dark',
          draggable: true,
          toastId: 'unknown-error',
          autoClose: false,
        });
      }
    }

    setLoadingAuth(false);
  };

  // authenticate user
  useEffect(() => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser || isAuthenticated || loginLoading || loadingAuth) return;

    async function authenticateUser() {
      setLoadingAuth(true);
      try {
        const session = await new Promise((resolve, reject) => {
          cognitoUser.getSession((err, session) => (err ? reject(err) : resolve(session)));
        });

        if (session.isValid()) {
          setAccessToken(session.accessToken.jwtToken);
          setUser(cognitoUser.getUsername());
          setEmail(session.getIdToken().payload.email);
          setTokenExpiryTime(session.getIdToken().getExpiration());

          // Fetch customer ID
          const customerData = await checkStripeCustomer();
          setCustomerId(customerData?.data?.customerId || null);
          if (!cookiesSet) {
            await createCookies(session);
            setCookiesSet(true);
          }

          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setError('Failed authentication');
        // eslint-disable-next-line no-console
        console.error('Failed authentication', error);
        if (error.message.startsWith('Token')) {
          toast.warn(`${error.message}`, {
            theme: 'dark',
            draggable: true,
            draggablePercent: 60,
            toastId: 'authContext-1',
            autoClose: 5000,
          });
        } else {
          toast.error(`Failed authentication: ${error}`, {
            theme: 'dark',
            draggable: true,
            draggablePercent: 60,
            toastId: 'authContext-2',
            autoClose: false,
          });
        }
        setIsAuthenticated(false);
      } finally {
        setLoadingAuth(false);
        setLoginLoading(false);
      }
    }

    authenticateUser();
  }, []);

  // check and refresh tokens
  useEffect(() => {
    if (!tokenExpiryTime) return;

    const timerId = setInterval(() => {
      const currentTimeInSeconds = Math.floor(Date.now() / 1000);
      const timeLeftInSeconds = tokenExpiryTime - currentTimeInSeconds;

      if (timeLeftInSeconds <= 295) {
        refreshTokens();
        clearInterval(timerId);
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [tokenExpiryTime]);

  //  sign out if tokens are invalid
  useEffect(() => {
    if (!isTokenValid) {
      handleSignOut(email, setUser, setIsAuthenticated, setCustomerId);
    }
  }, [isTokenValid, email, handleSignOut]);

  async function refreshTokens() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/refresh-tokens`, {
        method: 'POST',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to refresh tokens');
      }

      const data = await response.json();
      setTokenExpiryTime(data.accessTokenExpiry);
      setIsTokenValid(true); // Successfully refreshed tokens, mark as valid
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error refreshing tokens:', error);
      setIsTokenValid(false); // Refresh failed, mark tokens as invalid
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated, // added late for useAppBar.... make sure it's necessary
        user,
        setUser,
        email,
        setEmail,
        loadingAuth,
        handleSignOut,
        fetchAuth,
        handleRegistration,
        handleForgotPassword,
        accessToken,
        error,
        setError,
        password,
        setPassword,
        cPassword,
        setCPassword,
        customerId,
        setCustomerId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('massive fuck up');
  }
  return context;
};
export { AuthProvider, useAuthContext };
